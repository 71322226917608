import React from 'react';
import { AlertOctagon, AlertTriangle, Cpu, LucideMemoryStick, HardDrive, Calendar } from 'lucide-react';

export const ActionRequired = ({ replacementCandidates }) => {
  const parseProperties = (asset) => {
    try {
      return typeof asset.properties === 'string' 
        ? JSON.parse(asset.properties) 
        : asset.properties;
    } catch (e) {
      console.error('Error parsing properties:', e);
      return {};
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">
        <div className="flex items-center gap-2">
          <AlertOctagon className="text-red-500" />
          Dringende Maßnahmen Erforderlich
        </div>
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {replacementCandidates?.map((asset) => {
          const parsedProps = parseProperties(asset);
          const kabutoInfo = parsedProps?.kabuto_information || {};
          
          return (
            <div 
              key={asset.id} 
              className="bg-white rounded-lg border border-red-200 p-6 shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h3 className="font-medium text-lg text-gray-900">{asset.name}</h3>
                  <p className="text-sm text-gray-600">
                    {parsedProps.manufacturer} {parsedProps.model}
                  </p>
                </div>
                <div className="flex flex-col items-end">
                  <span className={`px-3 py-1 text-sm font-medium rounded-full ${
                    asset.high_cpu_incidents > 150 ? 'bg-red-100 text-red-800' : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {asset.high_cpu_incidents > 150 ? 'Kritisch' : 'Problematisch'}
                  </span>
                  <span className="text-sm text-gray-500 mt-1">
                    Health Score: {asset.health_score || 'N/A'}
                  </span>
                </div>
              </div>
              
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="flex items-center gap-2" title="Durchschnittliche CPU-Auslastung">
                  <Cpu className="text-blue-500" size={18} />
                  <div>
                    <p className="text-xs text-gray-500">CPU Auslastung</p>
                    <p className="font-medium text-gray-600">{asset.avg_cpu_usage?.toFixed(1)}%</p>
                  </div>
                </div>
                <div className="flex items-center gap-2" title="Durchschnittliche RAM-Auslastung">
                  <LucideMemoryStick className="text-green-500" size={18} />
                  <div>
                    <p className="text-xs text-gray-500">RAM Auslastung</p>
                    <p className="font-medium text-gray-600">{parseFloat(asset.avg_ram_usage).toFixed(1)}%</p>
                  </div>
                </div>
                <div className="flex items-center gap-2" title="Freier Speicherplatz">
                  <HardDrive className="text-purple-500" size={18} />
                  <div>
                    <p className="text-xs text-gray-500">Speicher frei</p>
                    <p className="font-medium text-gray-600">
                      {kabutoInfo.system_partition?.free_percent || 'N/A'}%
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2" title="Installationsdatum">
                  <Calendar className="text-orange-500" size={18} />
                  <div>
                    <p className="text-xs text-gray-500">Alter</p>
                    <p className="font-medium text-gray-600">
                      {Math.round((new Date() - new Date(asset.install_date)) / (1000 * 60 * 60 * 24 * 365))} Jahre
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-4 space-y-2 text-sm">
                <div className="flex items-center gap-2 text-gray-700">
                  <AlertTriangle size={16} className="text-amber-500" />
                  <span>Performance-Probleme:</span>
                </div>
                <ul className="list-disc list-inside pl-5 text-gray-600 space-y-1">
                  {asset.high_cpu_incidents > 0 && (
                    <li>{asset.high_cpu_incidents} CPU-Überlastungen</li>
                  )}
                  {asset.high_ram_incidents > 0 && (
                    <li>{asset.high_ram_incidents} RAM-Überlastungen</li>
                  )}
                  {parseFloat(asset.avg_ram_usage) > 80 && (
                    <li>Dauerhaft hohe RAM-Auslastung</li>
                  )}
                  {kabutoInfo.system_partition?.free_percent < 10 && (
                    <li>Kritisch wenig Speicherplatz</li>
                  )}
                </ul>
              </div>

              <div className="mt-4 pt-4 border-t border-gray-100">
                <h4 className="font-medium text-gray-900 mb-2">Upgrade-Empfehlung:</h4>
                <p className="text-sm text-gray-700">
                  {asset.upgrade_recommendation}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActionRequired;