import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, Legend
} from 'recharts';
import { Calendar, Timer } from 'lucide-react';
import { apiClient } from '../utils/apiClient';

const TIME_RANGES = [
  { label: 'Letzte Stunde', value: '1h', ms: 60 * 60 * 1000 },
  { label: '24 Stunden', value: '24h', ms: 24 * 60 * 60 * 1000 },
  { label: '7 Tage', value: '7d', ms: 7 * 24 * 60 * 60 * 1000 },
  { label: '30 Tage', value: '30d', ms: 30 * 24 * 60 * 60 * 1000 },
  { label: 'Benutzerdefiniert', value: 'custom' }
];

const REFRESH_INTERVALS = [
  { label: 'Aus', value: 0 },
  { label: '1s', value: 1000 },
  { label: '5s', value: 5000 },
  { label: '10s', value: 10000 },
  { label: '60s', value: 60000 }
];

const MEMORY_LABELS = {
  used_ram_gb: 'Verwendet',
  free_ram_gb: 'Verfügbar', 
  total_ram_gb: 'Gesamt',
  swap_used_gb: 'Swap verwendet'
};

const formatters = {
  timestamp: (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();
    
    return isToday 
      ? date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
      : date.toLocaleDateString('de-DE', { 
          day: '2-digit', 
          month: '2-digit',
          hour: '2-digit', 
          minute: '2-digit' 
        });
  },
  
  tooltipTimestamp: (timestamp) => {
    return new Date(timestamp).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  },

  cpu: (value) => `${value.toFixed(1)}%`,
  
  ram: (value) => `${value.toFixed(1)} GB`,
  
  network: (value) => {
    if (value === null || value === undefined) return '0 Mbps';
    return `${value.toFixed(1)} Mbps`;
  }
};

const ChartLoader = () => (
  <div className="bg-white p-6 rounded-lg border shadow-sm">
    <div className="h-[300px] flex items-center justify-center">
      <div className="text-gray-500">Lade Diagramm...</div>
    </div>
  </div>
);

const RefreshSelector = ({ selectedInterval, onIntervalChange }) => (
  <div className="flex items-center gap-2">
    <Timer className="w-4 h-4 text-gray-500" />
    <select
      value={selectedInterval}
      onChange={(e) => onIntervalChange(Number(e.target.value))}
      className="border rounded px-2 pr-8 py-1.5 text-sm bg-white text-gray-500 text-left"
    >
      {REFRESH_INTERVALS.map((interval) => (
        <option key={interval.value} value={interval.value} className="text-left">
          {interval.label}
        </option>
      ))}
    </select>
  </div>
);

const TimeRangeSelector = ({ selectedRange, onRangeChange, onCustomRange, selectedInterval, onIntervalChange }) => (
  <div className="flex items-center justify-between mb-6">
    <div className="flex items-center gap-4">
      <div className="flex gap-2">
        {TIME_RANGES.map((range) => (
          <button
            key={range.value}
            onClick={() => onRangeChange(range.value)}
            className={`px-3 py-1.5 rounded-lg border text-sm transition-colors ${
              selectedRange === range.value
                ? 'bg-gray-900 text-white border-gray-900'
                : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'
            }`}
          >
            {range.label}
          </button>
        ))}
      </div>
      {selectedRange === 'custom' && (
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <input
              type="datetime-local"
              onChange={(e) => onCustomRange('start', e.target.value)}
              className="border rounded px-2 py-1 text-sm"
            />
          </div>
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <input
              type="datetime-local"
              onChange={(e) => onCustomRange('end', e.target.value)}
              className="border rounded px-2 py-1 text-sm"
            />
          </div>
        </div>
      )}
    </div>
    <RefreshSelector 
      selectedInterval={selectedInterval}
      onIntervalChange={onIntervalChange}
    />
  </div>
);

const CustomLegend = ({ payload, activeItems, onItemClick }) => {
  if (!payload || !payload.length) return null;

  return (
    <div className="flex flex-wrap gap-4 mt-2 px-4">
      {payload.map((entry) => {
        const identifier = entry.dataKey;
        
        return (
          <div 
            key={identifier}
            onClick={() => onItemClick(identifier)}
            className={`flex items-center gap-2 cursor-pointer px-2 py-1 rounded transition-colors
              ${!activeItems.includes(identifier)  // Changed the condition here
                ? 'text-gray-400'
                : 'text-gray-900'}`}  // Changed the colors for better visibility
          >
            <div 
              className="w-3 h-3 rounded-full" 
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm">{entry.value}</span>
          </div>
        );
      })}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label, valueFormatter, metricName, dataLabels }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-lg">
      <p className="text-sm text-gray-600 mb-1">
        {formatters.tooltipTimestamp(label)}
      </p>
      {payload
        .filter(entry => entry.value !== undefined)
        .map((entry, index) => (
          <p key={index} className="text-sm font-medium text-gray-900">
            {dataLabels?.[entry.dataKey] || entry.name || metricName}: {valueFormatter(entry.value)}
          </p>
        ))}
    </div>
  );
};

const EmptyState = () => (
  <div className="h-full w-full flex items-center justify-center">
    <p className="text-gray-500">Keine Daten verfügbar für den ausgewählten Zeitraum</p>
  </div>
);

// Wrapped in React.memo for performance
const MetricChart = React.memo(({ 
  data, 
  title, 
  dataKey, 
  valueFormatter, 
  yAxisLabel, 
  metricName,
  showMultipleLines = false,
  dataLabels = {} 
}) => {
  const hasData = data && data.length > 0;
  const [activeItems, setActiveItems] = useState([]);

  useEffect(() => {
    if (hasData && showMultipleLines) {
      const initialActive = Object.keys(data[0]).filter(key => 
        key !== 'timestamp' && 
        !key.includes('error') && 
        !key.includes('drop')
      );
      setActiveItems(initialActive);
    }
  }, [data, showMultipleLines]);

  const handleLegendClick = (item) => {
    setActiveItems(prev => {
      if (prev.includes(item)) {
        return prev.filter(i => i !== item);
      }
      return [...prev, item];
    });
  };

  const getLines = () => {
    if (!showMultipleLines) {
      return [
        <Line 
          key={dataKey}
          type="monotone" 
          dataKey={dataKey}
          name={dataLabels[dataKey] || metricName}
          stroke="#1a1a1a" 
          dot={false}
          strokeWidth={2}
          isAnimationActive={false}
        />
      ];
    }

    const keys = Object.keys(data[0]).filter(key => 
      key !== 'timestamp' && 
      !key.includes('error') && 
      !key.includes('drop')
    );

    return keys.map((key, index) => (
      <Line
        key={key}
        type="monotone"
        dataKey={key}
        name={dataLabels[key] || key}
        stroke={`hsl(${index * (360 / keys.length)}, 70%, 50%)`}
        dot={false}
        strokeWidth={2}
        hide={!activeItems.includes(key)}
        isAnimationActive={false}
      />
    ));
  };

  return (
    <div className="bg-white p-6 rounded-lg border shadow-sm">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
      <div className="h-[300px]">
        {!hasData ? <EmptyState /> : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis 
                dataKey="timestamp" 
                tickFormatter={formatters.timestamp}
                stroke="#666"
              />
              <YAxis 
                label={{ 
                  value: yAxisLabel, 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { textAnchor: 'middle' }
                }}
                stroke="#666"
              />
              <Tooltip 
                content={<CustomTooltip 
                  valueFormatter={valueFormatter} 
                  metricName={metricName}
                  dataLabels={dataLabels}
                />}
              />
              {getLines()}
              {showMultipleLines && (
                <Legend 
                  content={<CustomLegend 
                    activeItems={activeItems}
                    onItemClick={handleLegendClick}
                  />} 
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
});

export const MetricsSection = ({ assetId }) => {
  const [timeRange, setTimeRange] = useState('24h');
  const [customRange, setCustomRange] = useState({ start: null, end: null });
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [metrics, setMetrics] = useState({
    cpu: [],
    ram: [],
    network: []
  });
  const [lastUpdateTime, setLastUpdateTime] = useState(null);

  const processRamData = useCallback((data) => {
    if (!data || !data.length) return [];
    
    return data.map(item => ({
      timestamp: item.timestamp,
      total_ram_gb: item.total_ram_bytes / (1024 * 1024 * 1024),
      used_ram_gb: item.used_ram_bytes / (1024 * 1024 * 1024),
      free_ram_gb: item.free_ram_bytes / (1024 * 1024 * 1024),
      swap_used_gb: item.swap_used_bytes / (1024 * 1024 * 1024)
    }));
  }, []);

  const processNetworkData = useCallback((data) => {
    if (!data || !data.length) return [];
    
    return data.reduce((acc, curr) => {
      const existing = acc.find(item => item.timestamp === curr.timestamp);
      if (existing) {
        existing[curr.interface_name] = curr.bandwidth_mbps;
      } else {
        acc.push({
          timestamp: curr.timestamp,
          [curr.interface_name]: curr.bandwidth_mbps
        });
      }
      return acc;
    }, []);
  }, []);

  const getTimeRange = useCallback(() => {
    if (timeRange === 'custom') {
      return {
        startTime: customRange.start,
        endTime: customRange.end
      };
    }

    const range = TIME_RANGES.find(r => r.value === timeRange);
    if (!range) {
      console.error('Invalid time range:', timeRange);
      return null;
    }

    return {
      endTime: new Date().toISOString(),
      startTime: new Date(Date.now() - range.ms).toISOString()
    };
  }, [timeRange, customRange]);

  const fetchFullMetrics = useCallback(async () => {
    const timeRange = getTimeRange();
    if (!timeRange) return;

    try {
      const [cpuData, ramData, networkData] = await Promise.all([
        apiClient.get(`/metrics/cpu/${assetId}?startTime=${timeRange.startTime}&endTime=${timeRange.endTime}`),
        apiClient.get(`/metrics/ram/${assetId}?startTime=${timeRange.startTime}&endTime=${timeRange.endTime}`),
        apiClient.get(`/metrics/network/${assetId}?startTime=${timeRange.startTime}&endTime=${timeRange.endTime}`)
      ]);

      const processedData = {
        cpu: cpuData.metrics || [],
        ram: processRamData(ramData.metrics || []),
        network: processNetworkData(networkData.metrics || [])
      };

      if (processedData.cpu.length || processedData.ram.length || processedData.network.length) {
        setMetrics(processedData);
        setLastUpdateTime(timeRange.endTime);
      }
    } catch (error) {
      console.error('Error fetching full metrics:', error);
    }
  }, [assetId, getTimeRange, processRamData, processNetworkData]);

  const mergeMetricsData = useCallback((oldData, newData, timeRangeMs) => {
    if (!oldData || !oldData.length) return newData || [];
    if (!newData || !newData.length) return oldData;

    // Create a map of existing timestamps for fast lookup
    const existingMap = new Map(
      oldData.map(item => [item.timestamp, item])
    );

    // Add or update with new data
    newData.forEach(item => {
      existingMap.set(item.timestamp, item);
    });

    // Convert back to array, sort, and filter by time range
    const cutoffTime = new Date(Date.now() - timeRangeMs);
    return Array.from(existingMap.values())
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      .filter(item => new Date(item.timestamp) > cutoffTime);
  }, []);

  const fetchIncrementalMetrics = useCallback(async () => {
    if (!lastUpdateTime) {
      await fetchFullMetrics();
      return;
    }

    // Get current time range configuration
    const currentTimeRange = getTimeRange();
    if (!currentTimeRange) return;

    const range = TIME_RANGES.find(r => r.value === timeRange);
    if (!range) return;

    // Calculate the overlap time - use 60 seconds for short intervals, longer for bigger intervals
    const overlapMs = Math.min(60000, range.ms * 0.1); // 10% of the time range or 60 seconds, whichever is smaller
    
    // Fetch with overlap to ensure we don't miss any data points
    const startTime = new Date(new Date(lastUpdateTime).getTime() - overlapMs).toISOString();
    const endTime = currentTimeRange.endTime;

    try {
      const [cpuData, ramData, networkData] = await Promise.all([
        apiClient.get(`/metrics/cpu/${assetId}?startTime=${startTime}&endTime=${endTime}`),
        apiClient.get(`/metrics/ram/${assetId}?startTime=${startTime}&endTime=${endTime}`),
        apiClient.get(`/metrics/network/${assetId}?startTime=${startTime}&endTime=${endTime}`)
      ]);

      setMetrics(prevMetrics => {
        // For network data, we need special handling due to dynamic interface names
        const mergeNetworkData = (oldData, newData) => {
          const processed = processNetworkData(newData || []);
          return mergeMetricsData(oldData, processed, range.ms);
        };

        const newMetrics = {
          cpu: mergeMetricsData(prevMetrics.cpu, cpuData.metrics || [], range.ms),
          ram: mergeMetricsData(prevMetrics.ram, processRamData(ramData.metrics || []), range.ms),
          network: mergeNetworkData(prevMetrics.network, networkData.metrics)
        };

        // Only update if we have new data
        if (newMetrics.cpu.length || newMetrics.ram.length || newMetrics.network.length) {
          return newMetrics;
        }
        return prevMetrics;
      });

      setLastUpdateTime(endTime);
    } catch (error) {
      console.error('Error fetching incremental metrics:', error);
    }
  }, [lastUpdateTime, timeRange, assetId, getTimeRange, processRamData, processNetworkData, mergeMetricsData, fetchFullMetrics]);

  // Effect for initial load and time range changes
  useEffect(() => {
    if (timeRange === 'custom' && (!customRange.start || !customRange.end)) {
      return;
    }
    
    // Reset metrics when changing time range to avoid showing stale data
    setMetrics({
      cpu: [],
      ram: [],
      network: []
    });
    setLastUpdateTime(null);
    
    fetchFullMetrics();
  }, [timeRange, customRange, fetchFullMetrics]);

  // Effect for auto-refresh
  useEffect(() => {
    let intervalId;
    
    if (refreshInterval > 0) {
      intervalId = setInterval(fetchIncrementalMetrics, refreshInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [refreshInterval, fetchIncrementalMetrics]);

  const handleCustomRange = (type, value) => {
    setCustomRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  return (
    <div className="space-y-6">
      <TimeRangeSelector 
        selectedRange={timeRange}
        onRangeChange={setTimeRange}
        onCustomRange={handleCustomRange}
        selectedInterval={refreshInterval}
        onIntervalChange={setRefreshInterval}
      />

      <div className="grid grid-cols-1 gap-6">
        <Suspense fallback={<ChartLoader />}>
          <MetricChart
            data={metrics.cpu}
            title="CPU-Auslastung"
            dataKey="cpu_usage_percent"
            valueFormatter={formatters.cpu}
            yAxisLabel="CPU-Auslastung (%)"
            metricName="CPU-Auslastung"
            dataLabels={{ cpu_usage_percent: 'CPU-Auslastung' }}
          />
        </Suspense>

        <Suspense fallback={<ChartLoader />}>
          <MetricChart
            data={metrics.ram}
            title="Arbeitsspeicher"
            dataKey="memory"
            valueFormatter={formatters.ram}
            yAxisLabel="Arbeitsspeicher (GB)"
            metricName="Arbeitsspeicher"
            showMultipleLines={true}
            dataLabels={MEMORY_LABELS}
          />
        </Suspense>

        <Suspense fallback={<ChartLoader />}>
          <MetricChart
            data={metrics.network}
            title="Netzwerkdurchsatz"
            valueFormatter={formatters.network}
            yAxisLabel="Durchsatz (Mbps)"
            metricName="Netzwerkdurchsatz"
            showMultipleLines={true}
          />
        </Suspense>
      </div>
    </div>
  );
};