import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { toast } from 'react-toastify';
import { LoaderContainer } from '../components/Loader';
import { 
  Search, Filter, MoreVertical, Server, HardDrive, LucideMemoryStick,
  Monitor, Shield, AlertTriangle, CheckCircle, Cpu, Globe,
  Calendar, Clock, AlertOctagon, Building
} from 'lucide-react';

const STATUS_BADGES = {
  'Online': 'bg-green-100 text-green-800 border-green-200',
  'Offline': 'bg-red-100 text-red-800 border-red-200',
  'Warning': 'bg-yellow-100 text-yellow-800 border-yellow-200',
};

const StatusBadge = ({ status }) => {
  const icon = status === 'Online' ? CheckCircle : AlertTriangle;
  const Icon = icon;
  return (
    <span className={`px-2.5 py-1 text-xs font-medium rounded-full border inline-flex items-center gap-1 ${STATUS_BADGES[status]}`}>
      <Icon size={12} />
      {status}
    </span>
  );
};

const MetricCard = ({ title, value, icon: Icon, className }) => (
  <div className={`bg-white p-4 rounded-lg border shadow-sm ${className}`}>
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-700">{title}</p>
        <p className="mt-1 text-xl font-semibold text-gray-700">{value}</p>
      </div>
      <Icon className="h-8 w-8 text-gray-400" />
    </div>
  </div>
);

const AssetCard = ({ asset, onClick }) => {
  const k = asset.properties?.kabuto_information ?? {};
  const hasWarnings = k.os?.pending_reboot || k.system_partition?.free_percent < 20;
  const status = k.monitoring ? (hasWarnings ? 'Warning' : 'Online') : 'Offline';

  return (
    <div 
      onClick={onClick}
      className="bg-white rounded-lg border hover:shadow-md transition-all cursor-pointer p-4"
    >
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center gap-3">
          <Server className="w-8 h-8 text-blue-600" />
          <div>
            <h3 className="font-medium text-gray-900">{asset.name}</h3>
            <p className="text-sm text-gray-700">{asset.asset_type}</p>
          </div>
        </div>
        <StatusBadge status={status} />
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p className="text-sm text-gray-700 mb-1">System</p>
          <p className="text-sm text-gray-700">{k.os?.name ?? 'N/A'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-700 mb-1">IP</p>
          <p className="text-sm text-gray-700">{k.general?.ip ?? 'N/A'}</p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2">
        <div className="text-center p-2 bg-gray-50 rounded">
          <LucideMemoryStick className="w-4 h-4 mx-auto mb-1 text-gray-400" />
          <p className="text-xs text-gray-600">{k.ram_gb ?? 0} GB</p>
        </div>
        <div className="text-center p-2 bg-gray-50 rounded">
          <HardDrive className="w-4 h-4 mx-auto mb-1 text-gray-400" />
          <p className="text-xs text-gray-600">{k.system_partition?.free_percent ?? 0}% frei</p>
        </div>
        <div className="text-center p-2 bg-gray-50 rounded">
          <Clock className="w-4 h-4 mx-auto mb-1 text-gray-400" />
          <p className="text-xs text-gray-600">{Math.floor((new Date() - new Date(k.os?.last_boot_time ?? 0)) / (1000 * 60 * 60 * 24))}d uptime</p>
        </div>
      </div>

      {hasWarnings && (
        <div className="mt-4 p-2 bg-yellow-50 rounded-lg border border-yellow-200">
          <div className="flex items-center gap-2 text-yellow-700">
            <AlertOctagon className="w-4 h-4" />
            <p className="text-xs">
              {k.os?.pending_reboot && "Neustart erforderlich"}
              {k.system_partition?.free_percent < 20 && "Wenig Speicherplatz"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export const AssetsPage = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'table'
  const [currentPage, setCurrentPage] = useState(1);
  const [assetsPerPage] = useState(12); // Number of assets per page
  const { user } = useAuth();
  const navigate = useNavigate();

  const loadAssets = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/assets/${user.syncroCompanyId}`);
      setAssets(response.assets ?? []);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Fehler beim Laden der Assets');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAssets();
  }, [user.syncroCompanyId]);

  const getMetrics = () => {
    const onlineCount = assets.filter(a => a.properties?.kabuto_information?.monitoring).length;
    const warningCount = assets.filter(a => {
      const k = a.properties?.kabuto_information ?? {};
      return k.os?.pending_reboot || k.system_partition?.free_percent < 20;
    }).length;
    const totalStorage = assets.reduce((acc, asset) => {
      return acc + parseInt(asset.properties?.kabuto_information?.system_partition?.size_gb ?? 0);
    }, 0);

    return [
      { title: 'Geräte Online', value: `${onlineCount}/${assets.length}`, icon: Server },
      { title: 'Warnungen', value: warningCount, icon: AlertTriangle },
      { title: 'Gesamtspeicher', value: `${totalStorage} GB`, icon: HardDrive }
    ];
  };

  const filteredAssets = assets
    .filter(asset => {
      const k = asset.properties?.kabuto_information ?? {};
      const hasWarnings = k.os?.pending_reboot || k.system_partition?.free_percent < 20;
      const status = k.monitoring ? (hasWarnings ? 'Warning' : 'Online') : 'Offline';
      
      const matchesSearch = searchTerm === '' || 
        asset.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.asset_serial?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        k.os?.name.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = selectedStatus === '' || status === selectedStatus;
      
      return matchesSearch && matchesStatus;
    });

  // Pagination logic
  const indexOfLastAsset = currentPage * assetsPerPage;
  const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
  const currentAssets = filteredAssets.slice(indexOfFirstAsset, indexOfLastAsset);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <LoaderContainer text="Assets werden geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          Fehler beim Laden der Assets: {error}
          <button
            onClick={loadAssets}
            className="ml-4 px-3 py-1 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header & Metrics */}
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">Assets</h1>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            {getMetrics().map((metric, index) => (
              <MetricCard key={index} {...metric} />
            ))}
          </div>
          
          {/* Filter Bar */}
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Assets durchsuchen..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
              </div>
              <button 
                className="inline-flex items-center px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50"
                onClick={() => setShowFilters(!showFilters)}
              >
                <Filter size={20} className="mr-2" />
                Filter
              </button>
              <div className="flex gap-2">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded-lg border ${viewMode === 'grid' ? 'bg-blue-50 border-blue-200' : 'bg-white border-gray-200'}`}
                >
                  <Monitor size={20} />
                </button>
                <button
                  onClick={() => setViewMode('table')}
                  className={`p-2 rounded-lg border ${viewMode === 'table' ? 'bg-blue-50 border-blue-200' : 'bg-white border-gray-200'}`}
                >
                  <Server size={20} />
                </button>
              </div>
            </div>

            {showFilters && (
              <div className="flex flex-wrap gap-4 p-4 bg-white rounded-lg border border-gray-200">
                <div className="flex-1 min-w-[200px]">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Status
                  </label>
                  <select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2"
                  >
                    <option value="">Alle Status</option>
                    {Object.keys(STATUS_BADGES).map(status => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={() => {
                    setSelectedStatus('');
                    setSearchTerm('');
                  }}
                  className="inline-flex items-center px-4 py-2 text-gray-700 bg-gray-50 border border-gray-200 rounded-lg hover:bg-gray-100"
                >
                  Filter zurücksetzen
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Assets Content */}
        {currentAssets.length === 0 ? (
          <div className="bg-white rounded-xl border p-8 text-center text-gray-700">
            Keine Assets gefunden
          </div>
        ) : viewMode === 'grid' ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {currentAssets.map((asset) => (
              <AssetCard
                key={asset.id}
                asset={asset}
                onClick={() => navigate(`/assets/${asset.id}`)}
              />
            ))}
          </div>
        ) : (
          <div className="bg-white shadow overflow-hidden rounded-xl border">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    System
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    Speicher
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    RAM
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    IP
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    Uptime
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentAssets.map((asset) => {
                  const k = asset.properties?.kabuto_information ?? {};
                  const hasWarnings = k.os?.pending_reboot || k.system_partition?.free_percent < 20;
                  const status = k.monitoring ? (hasWarnings ? 'Warning' : 'Online') : 'Offline';

                  return (
                    <tr
                      key={asset.id}
                      onClick={() => navigate(`/assets/${asset.id}`)}
                      className="hover:bg-gray-50 cursor-pointer"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <Server className="h-6 w-6 text-gray-400"/>
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{asset.name}</div>
                            <div className="text-sm text-gray-700">{asset.asset_type}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <StatusBadge status={status} />
                        {hasWarnings && (
                          <div className="mt-1 text-xs text-yellow-700">
                            {k.os?.pending_reboot && "Neustart erforderlich"}
                            {k.system_partition?.free_percent < 20 && "Wenig Speicherplatz"}
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{k.os?.name ?? 'N/A'}</div>
                        <div className="text-xs text-gray-700">Build {k.os?.build ?? 'N/A'}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {k.system_partition?.free_gb ?? 0} GB frei
                        </div>
                        <div className="text-xs text-gray-700">
                          von {k.system_partition?.size_gb ?? 0} GB ({k.system_partition?.free_percent ?? 0}%)
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {k.ram_gb ?? 0} GB
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{k.general?.ip ?? 'N/A'}</div>
                        <div className="text-xs text-gray-700">{k.primary_adapter?.ipv4 ?? 'N/A'}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {Math.floor((new Date() - new Date(k.os?.last_boot_time ?? 0)) / (1000 * 60 * 60 * 24))} Tage
                        </div>
                        <div className="text-xs text-gray-700">
                          {new Date(k.os?.last_boot_time ?? 0).toLocaleDateString()}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination Controls */}
        <div className="flex justify-center mt-8">
          <nav className="inline-flex -space-x-px">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
            >
              Previous
            </button>
            {Array.from({ length: Math.ceil(filteredAssets.length / assetsPerPage) }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => paginate(i + 1)}
                className={`px-3 py-2 leading-tight ${currentPage === i + 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredAssets.length / assetsPerPage)}
              className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
            >
              Next
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AssetsPage;
