import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { toast } from 'react-toastify';
import { LoaderContainer } from '../components/Loader';
import { Search, MoreVertical, AlertCircle, Book } from 'lucide-react';
import { ConstructionOverlay } from '../components/ConstructionOverlay';
const truncateText = (text, maxLength = 50) => {
  if (!text) return 'Kein Titel';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const formatDate = (dateString) => {
  try {
    return new Date(dateString).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (e) {
    return 'Ungültiges Datum';
  }
};



export const WikiPage = () => {
  const [wikiPages, setWikiPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const loadWikiPages = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/wiki/customer/${user.syncroCompanyId}`);
      setWikiPages(response.wiki_pages);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Fehler beim Laden der Wiki-Seiten');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadWikiPages();
  }, [user.syncroCompanyId]);

  const filteredWikiPages = wikiPages.filter(page => {
    const matchesSearch = searchTerm === '' || 
      (page.name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (page.body?.toLowerCase() || '').includes(searchTerm.toLowerCase());
    
    return matchesSearch;
  });

  const renderEmptyState = () => (
    <div className="flex flex-col items-center justify-center p-8 text-center text-gray-700">
      <Book size={48} className="text-gray-400 mb-4" />
      <h3 className="text-lg font-medium mb-2">Keine Wiki-Seiten gefunden</h3>
      <p className="text-gray-500 max-w-md">
        {searchTerm 
          ? 'Keine Wiki-Seiten entsprechen den Suchkriterien.' 
          : 'Es wurden noch keine Wiki-Seiten erstellt.'}
      </p>
    </div>
  );

  if (loading) {
    return <LoaderContainer text="Wiki-Seiten werden geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          <div className="flex items-center gap-2 mb-2">
            <AlertCircle size={20} />
            <span className="font-medium">Fehler beim Laden der Wiki-Seiten</span>
          </div>
          <p className="mb-4">{error}</p>
          <button
            onClick={loadWikiPages}
            className="px-4 py-2 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <ConstructionOverlay />
      <Helmet>
        <title>Wiki-Seiten - Armann Systems GmbH</title>
      </Helmet>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Wiki-Seiten</h1>
              <p className="mt-1 text-sm text-gray-500">
                {filteredWikiPages.length} {filteredWikiPages.length === 1 ? 'Seite' : 'Seiten'} gefunden
              </p>
            </div>
          </div>

          {/* Filter Bar */}
          <div className="mt-4 space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Wiki-Seiten durchsuchen..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
              </div>
            </div>
          </div>
        </div>

        {/* Wiki Pages Table */}
        <div className="bg-white shadow-sm rounded-xl border border-gray-100 overflow-hidden">
          {filteredWikiPages.length === 0 ? (
            renderEmptyState()
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Erstellt
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Aktualisiert
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {filteredWikiPages.map((page) => (
                    <tr
                      key={page.id}
                      onClick={() => navigate(`/wiki/${page.id}`)}
                      className="hover:bg-gray-50 cursor-pointer transition-colors"
                    >
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {truncateText(page.name)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatDate(page.created_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatDate(page.updated_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <button className="text-gray-400 hover:text-gray-900">
                          <MoreVertical size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};