import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { LoaderContainer } from '../components/Loader';
import { toast } from 'react-toastify';
import { StatHeader } from '../components/statistics/StatHeader';
import { ActionRequired } from '../components/statistics/ActionRequired';
import { ROIAnalysis } from '../components/statistics/ROIAnalysis';
import { PerformanceHotspots } from '../components/statistics/PerformanceHotspots';
import { PerformanceTrendsChart } from '../components/statistics/PerformanceTrendsChart';
import { HealthScores } from '../components/statistics/HealthScores';
import { SoftwareIssues } from '../components/statistics/SoftwareIssues';

export const StatisticsPage = () => {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [selectedTimeRange, setSelectedTimeRange] = useState('30d');

  useEffect(() => {
    const loadInsights = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(`/statistics/insights/${user.syncroCompanyId}`);
        setInsights(response.insights);
      } catch (error) {
        toast.error('Fehler beim Laden der Insights');
      } finally {
        setLoading(false);
      }
    };

    loadInsights();
  }, [user.syncroCompanyId]);

  if (loading) {
    return <LoaderContainer text="Lade System Insights..." />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>System Insights - Armann Systems GmbH</title>
      </Helmet>

      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <StatHeader />
        <ActionRequired replacementCandidates={insights?.replacement_candidates} />
        <ROIAnalysis 
          roiAnalysis={insights?.roi_analysis}
          replacementCandidatesCount={insights?.replacement_candidates?.length}
        />
        <PerformanceHotspots hotspots={insights?.problem_hotspots} />
        <PerformanceTrendsChart data={insights?.performance_trends} />
        <HealthScores healthScores={insights?.health_scores} />
        <SoftwareIssues issues={insights?.software_issues} />
      </div>
    </div>
  );
};

export default StatisticsPage;