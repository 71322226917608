import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { toast } from 'react-toastify';
import { LoaderContainer } from '../components/Loader';
import { Plus, Search, Filter, MoreVertical, AlertCircle } from 'lucide-react';

// Normalisierte Status-Definitionen
const STATUS_BADGES = {
  'New': { color: 'bg-blue-100 text-blue-800 border-blue-200', label: 'Neu' },
  'In Progress': { color: 'bg-yellow-100 text-yellow-800 border-yellow-200', label: 'In Bearbeitung' },
  'Resolved': { color: 'bg-green-100 text-green-800 border-green-200', label: 'Gelöst' },
  'Closed': { color: 'bg-gray-100 text-gray-800 border-gray-200', label: 'Geschlossen' },
  'default': { color: 'bg-purple-100 text-purple-800 border-purple-200', label: 'Unbekannt' }
};

// Normalisierte Prioritäts-Definitionen
const PRIORITY_BADGES = {
  'Low': { color: 'bg-blue-100 text-blue-800 border-blue-200', label: 'Niedrig' },
  'Normal': { color: 'bg-green-100 text-green-800 border-green-200', label: 'Normal' },
  '2 Normal': { color: 'bg-green-100 text-green-800 border-green-200', label: 'Normal' },
  'High': { color: 'bg-yellow-100 text-yellow-800 border-yellow-200', label: 'Hoch' },
  'Emergency': { color: 'bg-red-100 text-red-800 border-red-200', label: 'Notfall' },
  'default': { color: 'bg-gray-100 text-gray-800 border-gray-200', label: 'Keine Priorität' }
};

// Helfer-Funktionen für die Datenverarbeitung
const formatDate = (dateString) => {
  try {
    return new Date(dateString).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (e) {
    return 'Ungültiges Datum';
  }
};

const getStatusBadge = (status) => {
  return STATUS_BADGES[status] || STATUS_BADGES.default;
};

const getPriorityBadge = (priority) => {
  return PRIORITY_BADGES[priority] || PRIORITY_BADGES.default;
};

const truncateText = (text, maxLength = 50) => {
  if (!text) return 'Kein Betreff';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const TicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const loadTickets = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/tickets/customer/${user.syncroCompanyId}`);
      setTickets(response.tickets);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Fehler beim Laden der Tickets');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTickets();
  }, [user.syncroCompanyId]);

  const filteredTickets = tickets
    .filter(ticket => {
      const matchesSearch = searchTerm === '' || 
        (ticket.subject?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (ticket.ticket_number?.toString() || '').includes(searchTerm.toLowerCase());
      
      const matchesStatus = selectedStatus === '' || ticket.status === selectedStatus;
      const matchesPriority = selectedPriority === '' || ticket.priority === selectedPriority;
      
      return matchesSearch && matchesStatus && matchesPriority;
    });

  const renderEmptyState = () => (
    <div className="flex flex-col items-center justify-center p-8 text-center text-gray-700">
      <AlertCircle size={48} className="text-gray-400 mb-4" />
      <h3 className="text-lg font-medium mb-2">Keine Tickets gefunden</h3>
      <p className="text-gray-500 max-w-md">
        {searchTerm || selectedStatus || selectedPriority 
          ? 'Keine Tickets entsprechen den ausgewählten Filterkriterien.' 
          : 'Es wurden noch keine Tickets erstellt.'}
      </p>
    </div>
  );

  if (loading) {
    return <LoaderContainer text="Tickets werden geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          <div className="flex items-center gap-2 mb-2">
            <AlertCircle size={20} />
            <span className="font-medium">Fehler beim Laden der Tickets</span>
          </div>
          <p className="mb-4">{error}</p>
          <button
            onClick={loadTickets}
            className="px-4 py-2 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Support Tickets</h1>
              <p className="mt-1 text-sm text-gray-500">
                {filteredTickets.length} {filteredTickets.length === 1 ? 'Ticket' : 'Tickets'} gefunden
              </p>
            </div>
            <button
              onClick={() => navigate('/tickets/new')}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            >
              <Plus size={20} className="mr-2" />
              Neues Ticket
            </button>
          </div>
          
          {/* Ticket Statistics */}
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Total Tickets */}
          <div className="bg-white p-4 rounded-xl border border-gray-100">
            <h3 className="text-sm font-medium text-gray-500">Gesamt Tickets</h3>
            <p className="mt-2 text-3xl font-bold text-gray-900">{tickets.length}</p>
          </div>

          {/* Open Tickets */}
          <div className="bg-white p-4 rounded-xl border border-gray-100">
            <h3 className="text-sm font-medium text-gray-500">Offene Tickets</h3>
            <p className="mt-2 text-3xl font-bold text-blue-600">
              {tickets.filter(t => t.status === 'New' || t.status === 'In Progress').length}
            </p>
          </div>

          {/* High Priority Tickets */}
          <div className="bg-white p-4 rounded-xl border border-gray-100">
            <h3 className="text-sm font-medium text-gray-500">Hohe Priorität</h3>
            <p className="mt-2 text-3xl font-bold text-yellow-600">
              {tickets.filter(t => t.priority === 'High' || t.priority === 'Emergency').length}
            </p>
          </div>

          {/* Resolved Tickets */}
          <div className="bg-white p-4 rounded-xl border border-gray-100">
            <h3 className="text-sm font-medium text-gray-500">Gelöste Tickets</h3>
            <p className="mt-2 text-3xl font-bold text-green-600">
              {tickets.filter(t => t.status === 'Resolved' || t.status === 'Closed').length}
            </p>
          </div>
        </div>

          {/* Filter Bar */}
          <div className="mt-4 space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Tickets durchsuchen..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
              </div>
              <button 
                className={`inline-flex items-center px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors ${showFilters ? 'bg-gray-50' : ''}`}
                onClick={() => setShowFilters(!showFilters)}
              >
                <Filter size={20} className="mr-2" />
                Filter {showFilters ? 'ausblenden' : 'anzeigen'}
              </button>
            </div>

            {/* Filter Options */}
            {showFilters && (
              <div className="flex flex-wrap gap-4 p-4 bg-white rounded-lg border border-gray-200">
                <div className="flex-1 min-w-[200px]">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Status
                  </label>
                  <select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                  >
                    <option value="">Alle Status</option>
                    {Object.entries(STATUS_BADGES)
                      .filter(([key]) => key !== 'default')
                      .map(([key, value]) => (
                        <option key={key} value={key}>{value.label}</option>
                      ))}
                  </select>
                </div>
                <div className="flex-1 min-w-[200px]">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Priorität
                  </label>
                  <select
                    value={selectedPriority}
                    onChange={(e) => setSelectedPriority(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                  >
                    <option value="">Alle Prioritäten</option>
                    {Object.entries(PRIORITY_BADGES)
                      .filter(([key]) => key !== 'default')
                      .map(([key, value]) => (
                        <option key={key} value={key}>{value.label}</option>
                      ))}
                  </select>
                </div>
                <button
                  onClick={() => {
                    setSelectedStatus('');
                    setSelectedPriority('');
                    setSearchTerm('');
                  }}
                  className="inline-flex items-center px-4 py-2 text-gray-700 bg-gray-50 border border-gray-200 rounded-lg hover:bg-gray-100"
                >
                  Filter zurücksetzen
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Tickets Table */}
        <div className="bg-white shadow-sm rounded-xl border border-gray-100 overflow-hidden">
          {filteredTickets.length === 0 ? (
            renderEmptyState()
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Ticket #
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Betreff
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Priorität
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Erstellt
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Aktualisiert
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {filteredTickets.map((ticket) => (
                    <tr
                      key={ticket.id}
                      onClick={() => navigate(`/tickets/${ticket.id}`)}
                      className="hover:bg-gray-50 cursor-pointer transition-colors"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        #{ticket.ticket_number || '—'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {truncateText(ticket.subject)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2.5 py-1 text-xs font-medium rounded-full border ${getStatusBadge(ticket.status).color}`}>
                          {getStatusBadge(ticket.status).label}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2.5 py-1 text-xs font-medium rounded-full border ${getPriorityBadge(ticket.priority).color}`}>
                          {getPriorityBadge(ticket.priority).label}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatDate(ticket.created_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatDate(ticket.updated_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <button className="text-gray-400 hover:text-gray-900"><MoreVertical size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        
        
      </div>
    </div>
  );
};