import React, { useState } from 'react';
import { Camera, X } from 'lucide-react';
import QuaggaScanner from './QuaggaScanner';

const NavbarScanner = ({ onClose }) => {
  const [error, setError] = useState('');
  const [isScanning, setIsScanning] = useState(false);

  const handleScanSuccess = (decodedText) => {
    console.log("Scan erfolgreich:", decodedText);
    // Remove "RS-" prefix and leading zeros
    const cleanedText = decodedText.replace(/^RS-0*/, '');
    window.location.href = `https://support.armann-systems.com/assets/${cleanedText}`;
  };

  const handleScanError = (errorMessage) => {
    console.debug("Scan error:", errorMessage);
    setError(errorMessage); // Display error to the user
  };

  const handleClose = () => {
    setIsScanning(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Asset Scanner</h3>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden">
          {!isScanning ? (
            <div className="absolute inset-0 flex flex-col items-center justify-center gap-4 p-4">
              <button
                onClick={() => setIsScanning(true)}
                className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Camera size={20} />
                Scanner aktivieren
              </button>
              {error && (
                <p className="text-sm text-red-600 text-center">
                  {error}
                </p>
              )}
            </div>
          ) : (
            <QuaggaScanner onDetected={handleScanSuccess} />
          )}
        </div>

        <p className="mt-4 text-sm text-gray-600 text-center">
          Halten Sie die Kamera über den Barcode des Assets
        </p>
      </div>
    </div>
  );
};

export default NavbarScanner;
