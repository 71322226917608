import React from 'react';
import { DollarSign, RefreshCcw, TrendingUp, Calendar } from 'lucide-react';

export const ROIAnalysis = ({ roiAnalysis, replacementCandidatesCount }) => (
  <div className="mb-8">
    <h2 className="text-lg font-semibold text-gray-900 mb-4">
      <div className="flex items-center gap-2">
        <DollarSign className="text-green-600" />
        ROI & Kostenanalyse
      </div>
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="bg-white rounded-lg border p-6">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-sm font-medium text-gray-600">Geschätzte Ersatzkosten</h3>
          <RefreshCcw className="text-gray-400 w-4 h-4" />
        </div>
        <p className="text-2xl font-bold text-gray-900">
          €{Math.round(roiAnalysis?.total_replacement_cost).toLocaleString()}
        </p>
        <p className="text-xs text-gray-500 mt-1">
          Basierend auf {replacementCandidatesCount} Geräten
        </p>
      </div>

      <div className="bg-white rounded-lg border p-6">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-sm font-medium text-gray-600">Jährliche Einsparungen</h3>
          <TrendingUp className="text-green-500 w-4 h-4" />
        </div>
        <p className="text-2xl font-bold text-green-600">
          €{Math.round(roiAnalysis?.estimated_annual_savings).toLocaleString()}
        </p>
        <p className="text-xs text-gray-500 mt-1">
          Durch reduzierte Wartung & höhere Produktivität
        </p>
      </div>

      <div className="bg-white rounded-lg border p-6">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-sm font-medium text-gray-600">Amortisationszeit</h3>
          <Calendar className="text-blue-500 w-4 h-4" />
        </div>
        <p className="text-2xl font-bold text-gray-900">
          {Math.round(roiAnalysis?.payback_period * 12)} Monate
        </p>
        <p className="text-xs text-gray-500 mt-1">
          Geschätzte Zeit bis zur Rentabilität
        </p>
      </div>
    </div>
  </div>
);