
//debug
//const API_BASE_URL = 'http://192.168.250.114:3001/api';

//prod
const API_BASE_URL = 'https://support.armann-systems.com/api';
export class ApiError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;
    this.name = 'ApiError';
  }
}

export const apiClient = {
  async fetch(endpoint, options = {}) {
    const token = localStorage.getItem('token'); // Token aus dem LocalStorage holen
    const storedUser = localStorage.getItem('user');
    const syncroCompanyId = storedUser ? JSON.parse(storedUser).syncroCompanyId : null; // Retrieve syncroCompanyId from user object
    const defaultOptions = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}), // Token hinzufügen, falls vorhanden
        ...(syncroCompanyId ? { 'X-Syncro-Company-ID': syncroCompanyId } : {}), // Add syncroCompanyId to headers if available
        ...options.headers,
      },
    };

    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        ...defaultOptions,
        ...options,
        headers: {
          ...defaultOptions.headers,
          ...options.headers,
        },
      });

      if (response.status === 204) {
        return null;
      }

      const data = await response.json();

      if (!response.ok) {
        throw new ApiError(
          data.message || 'API request failed',
          response.status,
          data
        );
      }

      return data;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        throw new ApiError('Network error - Unable to connect to the server', 0);
      }
      throw new ApiError(error.message, 500);
    }
  },

  get(endpoint) {
    return this.fetch(endpoint);
  },

  post(endpoint, data) {
    return this.fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },

  put(endpoint, data) {
    return this.fetch(endpoint, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  },

  delete(endpoint) {
    return this.fetch(endpoint, {
      method: 'DELETE',
    });
  },
};
