import React, { useState, useRef } from 'react';
import { Printer, Download, ChevronDown, ChevronUp } from 'lucide-react';
import { QRCodeCanvas } from 'qrcode.react';

const PrintLabel = ({ asset }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    name: true,
    serialNumber: true,
    support: true
  });
  const qrRef = useRef();

  // EZPL (ZPL) Generierung
  const generateEZPL = () => {
    const commands = [
      '^XA',  // Start Label
      '^CI28', // UTF-8 Encoding
      '^PW831', // Druckbreite (831 dots = ~104mm)
      '^LL370', // Labellänge (370 dots = ~46mm)
      '^LH0,0', // Label Home Position
      
      // Firmenname
      '^FO20,20^A0N,25,25^FDArmann Systems^FS',
      
      // Logo Position (durch Text ersetzt)
      '^FO20,50^GB100,3,3^FS', // Trennlinie
      
      // Gerätename (wenn ausgewählt)
      selectedFields.name ? [
        '^FO20,70^A0N,20,20^FDGerätename:^FS',
        `^FO20,95^A0N,35,35^FD${asset?.name || 'N/A'}^FS`
      ].join('\n') : '',
      
      // Seriennummer (wenn ausgewählt)
      selectedFields.serialNumber ? [
        '^FO20,140^A0N,20,20^FDSeriennummer:^FS',
        `^FO20,165^A0N,28,28^FD${asset?.asset_serial || 'N/A'}^FS`
      ].join('\n') : '',
      
      // Support Info (wenn ausgewählt)
      selectedFields.support ? [
        '^FO20,220^A0N,20,20^FDSupport:^FS',
        '^FO20,245^A0N,20,20^FD+49 9401 91791 500^FS',
        '^FO20,270^A0N,20,20^FDsupport@armann-systems.com^FS',
        '^FO20,295^A0N,20,20^FDarmann-systems.com^FS'
      ].join('\n') : '',

      // QR Code (rechtsbündig)
      `^FO500,20^BQN,2,4^FDQA,https://support.armann-systems.com/assets/${asset?.id || ''}^FS`,
      
      // Rahmen um QR Code
      '^FO495,15^GB240,240,2^FS',
      
      '^XZ'  // Ende Label
    ].join('\n');

    return commands;
  };

  // EZPX Generierung
  const generateEZPX = () => {
    const ezpxContent = `<?xml version="1.0" encoding="utf-8"?>
<PrintJob xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
  <QLabelSDKVersion>1.5.7681.30436</QLabelSDKVersion>
  <GoLabelZoomFactor>0.5</GoLabelZoomFactor>
  <Label>
    <SerialFormat>
      ${Array(100).fill('<string xsi:nil="true" />').join('\n      ')}
    </SerialFormat>
    <SerialLeadingCode>
      ${Array(100).fill('<int>0</int>').join('\n      ')}
    </SerialLeadingCode>
    <SerialCustomSequence>
      ${Array(100).fill('<string xsi:nil="true" />').join('\n      ')}
    </SerialCustomSequence>
    <VariableFormat>
      ${Array(40).fill('<string xsi:nil="true" />').join('\n      ')}
    </VariableFormat>
    <qlabel>
      ${selectedFields.name ? `
      <!-- Gerätename Label -->
      <GraphicShape xsi:type="WindowTextBox" Style="Cross" IsPrint="true" PageAlignment="None" Locked="false" bStroke="true" bFill="true" Direction="Angle0" X="22" Y="105" Alignment="Left" AlignPointX="50" AlignPointY="106" FontScript="Default" AutoFontResize="false" FontCmd="Arial Narrow,5,I" LineSpacing="Single" LineHeight="25" Multiple="10" DotBeforePara="0" DotAfterPara="0" FontWidth="12" TextSpace="0">
        <qHitOnCircumferance>false</qHitOnCircumferance>
        <Selected>false</Selected>
        <iBackground_color>4294967295</iBackground_color>
        <Id>1</Id>
        <ItemLabel>GdiWindo00388</ItemLabel>
        <ObjectDrawMode>FW</ObjectDrawMode>
        <Name>W</Name>
        <BoundRectWidth>276</BoundRectWidth>
        <DispData>Geräte Name</DispData>
        <Data>Geräte Name</Data>
        <BoundRectHeight>28</BoundRectHeight>
        <BTrueType>true</BTrueType>
      </GraphicShape>

      <!-- Gerätename Wert -->
      <GraphicShape xsi:type="WindowTextBox" Style="Cross" IsPrint="true" PageAlignment="None" Locked="false" bStroke="true" bFill="true" Direction="Angle0" X="22" Y="44" Alignment="Left" AlignPointX="22" AlignPointY="44" FontScript="Default" AutoFontResize="false" FontCmd="Arial Narrow,16" LineSpacing="Single" LineHeight="78" Multiple="10" DotBeforePara="0" DotAfterPara="0" FontWidth="12" TextSpace="0">
        <qHitOnCircumferance>false</qHitOnCircumferance>
        <Selected>false</Selected>
        <iBackground_color>4294967295</iBackground_color>
        <Id>2</Id>
        <ItemLabel>GdiWindo22489</ItemLabel>
        <ObjectDrawMode>FW</ObjectDrawMode>
        <Name>W</Name>
        <BoundRectWidth>324</BoundRectWidth>
        <DispData>${escapeXml(asset?.name || 'N/A')}</DispData>
        <Data>${escapeXml(asset?.name || 'N/A')}</Data>
        <BoundRectHeight>70</BoundRectHeight>
        <BTrueType>true</BTrueType>
      </GraphicShape>
      ` : ''}

      ${selectedFields.serialNumber ? `
      <!-- Seriennummer -->
      <GraphicShape xsi:type="WindowTextBox" Style="Cross" IsPrint="true" PageAlignment="None" Locked="false" bStroke="true" bFill="true" Direction="Angle0" X="22" Y="136" Alignment="Left" AlignPointX="22" AlignPointY="136" FontScript="Default" AutoFontResize="false" FontCmd="Arial Narrow,10" LineSpacing="Single" LineHeight="49" Multiple="10" DotBeforePara="0" DotAfterPara="0" FontWidth="12" TextSpace="0">
        <qHitOnCircumferance>false</qHitOnCircumferance>
        <Selected>false</Selected>
        <iBackground_color>4294967295</iBackground_color>
        <Id>3</Id>
        <ItemLabel>GdiWindo41617</ItemLabel>
        <ObjectDrawMode>FW</ObjectDrawMode>
        <Name>W</Name>
        <BoundRectWidth>326</BoundRectWidth>
        <DispData>${escapeXml(asset?.asset_serial || 'N/A')}</DispData>
        <Data>${escapeXml(asset?.asset_serial || 'N/A')}</Data>
        <BoundRectHeight>50</BoundRectHeight>
        <BTrueType>true</BTrueType>
      </GraphicShape>

      <!-- S/N Label -->
      <GraphicShape xsi:type="WindowTextBox" Style="Cross" IsPrint="true" PageAlignment="None" Locked="false" bStroke="true" bFill="true" Direction="Angle0" X="22" Y="175" Alignment="Left" AlignPointX="26" AlignPointY="170" FontScript="Default" AutoFontResize="false" FontCmd="Arial Narrow,5,I" LineSpacing="Single" LineHeight="25" Multiple="10" DotBeforePara="0" DotAfterPara="0" FontWidth="12" TextSpace="0">
        <qHitOnCircumferance>false</qHitOnCircumferance>
        <Selected>false</Selected>
        <iBackground_color>4294967295</iBackground_color>
        <Id>4</Id>
        <ItemLabel>GdiWindo83636</ItemLabel>
        <ObjectDrawMode>FW</ObjectDrawMode>
        <Name>W</Name>
        <BoundRectWidth>252</BoundRectWidth>
        <DispData>S/N</DispData>
        <Data>S/N</Data>
        <BoundRectHeight>28</BoundRectHeight>
        <BTrueType>true</BTrueType>
      </GraphicShape>
      ` : ''}

      ${selectedFields.support ? `
      <!-- Support Info -->
      <GraphicShape xsi:type="WindowTextBox" Style="Cross" IsPrint="true" PageAlignment="None" Locked="false" bStroke="true" bFill="true" Direction="Angle0" X="22" Y="334" Alignment="Left" AlignPointX="44" AlignPointY="316" FontScript="Default" AutoFontResize="false" FontCmd="Arial Narrow,8" LineSpacing="Exact" LineHeight="30" Multiple="10" DotBeforePara="0" DotAfterPara="0" FontWidth="12" TextSpace="0">
        <qHitOnCircumferance>false</qHitOnCircumferance>
        <Selected>false</Selected>
        <iBackground_color>4294967295</iBackground_color>
        <Id>5</Id>
        <ItemLabel>GdiWindo08209</ItemLabel>
        <ObjectDrawMode>FW</ObjectDrawMode>
        <Name>W</Name>
        <BoundRectWidth>522</BoundRectWidth>
        <DispData>FON   +49 9401 91791 500
MAIL   support@armann-systems.com
WEB   https://armann-systems.com</DispData>
        <Data>FON   +49 9401 91791 500
MAIL   support@armann-systems.com
WEB   https://armann-systems.com</Data>
        <BoundRectHeight>126</BoundRectHeight>
        <BTrueType>true</BTrueType>
      </GraphicShape>
      ` : ''}

      <!-- QR Code -->
      <GraphicShape xsi:type="QRCode" Style="Cross" IsPrint="true" PageAlignment="None" Locked="false" bStroke="true" bFill="true" Direction="Angle0" X="653" Y="226" Alignment="Center" AlignPointX="653" AlignPointY="226" FontScript="Default" Mode="Auto" Type="2" ErrorCorrectionLevel="77" QrcodeVersion="0" Mask="8" Multiple="8" IsUTF8="true" CodePage="0">
        <qHitOnCircumferance>false</qHitOnCircumferance>
        <Selected>false</Selected>
        <iBackground_color>4294967295</iBackground_color>
        <Id>6</Id>
        <ItemLabel>QRCode_3</ItemLabel>
        <ObjectDrawMode>FW</ObjectDrawMode>
        <Name>W</Name>
        <BoundRectWidth>200</BoundRectWidth>
        <DispData>https://support.armann-systems.com/assets/${escapeXml(asset?.id || '')}</DispData>
        <Data>https://support.armann-systems.com/assets/${escapeXml(asset?.id || '')}</Data>
        <BoundRectHeight>200</BoundRectHeight>
      </GraphicShape>

      <!-- Info Text -->
      <GraphicShape xsi:type="WindowTextBox" Style="Cross" IsPrint="true" PageAlignment="None" Locked="false" bStroke="true" bFill="true" Direction="Angle0" X="22" Y="206" Alignment="Left" AlignPointX="22" AlignPointY="206" FontScript="Default" FontCmd="Arial Narrow,5,I" LineSpacing="Exact" LineHeight="20" Multiple="10" DotBeforePara="0" DotAfterPara="0" FontWidth="24" BTrueType="true">
        <qHitOnCircumferance>false</qHitOnCircumferance>
        <Selected>false</Selected>
        <iBackground_color>4294967295</iBackground_color>
        <Id>7</Id>
        <ItemLabel>WindowTextBox_19</ItemLabel>
        <ObjectDrawMode>FW</ObjectDrawMode>
        <Name>W</Name>
        <BoundRectWidth>630</BoundRectWidth>
        <DispData>Dieses Produkt wurde von der Armann Systems GmbH bereitgestellt. Das Öffnen oder Verändern des Produkts führt zum Verlust von Garantieleistungen. Für weitere Informationen kontaktieren Sie bitte unser Support-Team.</DispData>
        <Data>Dieses Produkt wurde von der Armann Systems GmbH bereitgestellt. Das Öffnen oder Verändern des Produkts führt zum Verlust von Garantieleistungen. Für weitere Informationen kontaktieren Sie bitte unser Support-Team.</Data>
        <BoundRectHeight>82</BoundRectHeight>
        <SplitDataByAI>false</SplitDataByAI>
        <WrapMode>WordWrap</WrapMode>
        <TextSpace>0</TextSpace>
      </GraphicShape>
    </qlabel>
  </Label>
  <Setup bInfinityPrint="false" LabelLength="37" LabelWidth="74" LeftMargin="0" TopMargin="0" LabelType="0" GapLength="3" FeedLength="0" ZSign="45" BlackMark="0" Position="0" Speed="4" Copy="1" Stripper="0" LabelsPerCut="1" Rotate180="255" Stop="26" Darkness="8" Number="1" bBatchCut="false" bNumberDataBase="false" NumberField="None" PageDirection="Portrait" PrintMode="0" PowerRFID="0" LengthRFID="-1" RetryRFID="1" DrawMode="0">
    <Layout Shape="2" AcrossType="Copied" PageDirection="Portrait" HorAcross="1" VerAcross="1" HorGap="0" VerGap="0" HorAcrossMode1="1" VerAcrossMode1="1" LabelMode="0" HorGapMode1="0" VerGapMode1="0" BottomMargin="0" RightMargin="0" />
    <Description>Lang:(de-DE) OS:Microsoft Windows NT 6.2.9200.0(Win32NT)</Description>
    <UnitType>Mm</UnitType>
    <Dpi>300</Dpi>
  </Setup>
  <PrinterModel>BP730i</PrinterModel>
  <PrinterLanguage>EZPL</PrinterLanguage>
  <CommunicationType>Network</CommunicationType>
</PrintJob>`;
    
    return ezpxContent;
  };

  // XML Escaping Hilfsfunktion
  const escapeXml = (unsafe) => {
    if (!unsafe) return '';
    return unsafe.toString()
      .replace(/[<>&'"]/g, c => ({
        '<': '&lt;',
        '>': '&gt;',
        '&': '&amp;',
        "'": '&apos;',
        '"': '&quot;'
      }[c]));
  };

  // Download Handler
  const downloadEZPL = () => {
    const content = generateEZPL();
    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `etikett-${asset?.asset_serial || 'asset'}.zpl`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const downloadEZPX = () => {
    const content = generateEZPX();
    const blob = new Blob([content], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `etikett-${asset?.asset_serial || 'asset'}.ezpx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // Browser Print Handler
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const qrCanvas = qrRef.current?.querySelector('canvas');
    const qrDataUrl = qrCanvas?.toDataURL() || '';
    
    const content = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Asset Etikett</title>
          <style>
            @page {
              size: 62mm 29mm;
              margin: 0;
            }
            body {
              margin: 0;
              padding: 3mm;
              width: 56mm;
              height: 23mm;
              font-family: system-ui, -apple-system, sans-serif;
              display: flex;
            }
            .content {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              max-width: 60%;
            }
            .header {
              margin-bottom: 2mm;
            }
            .company-name {
              font-size: 8pt;
              font-weight: 600;
              text-transform: uppercase;
              color: #000;
              border-bottom: 0.5pt solid #000;
              padding-bottom: 1mm;
              margin-bottom: 2mm;
            }
            .device-info {
              margin: 2mm 0;
            }
            .label {
              font-size: 6pt;
              color: #666;
              margin-bottom: 0.5mm;
            }
            .device-name {
              font-size: 10pt;
              font-weight: 600;
              margin-bottom: 2mm;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .serial-number {
              font-size: 9pt;
              font-family: monospace;
              color: #444;
            }
            .support-info {
              font-size: 6pt;
              color: #666;
              line-height: 1.3;
            }
            .qr-section {
              position: absolute;
              right: 3mm;
              top: 3mm;
              width: 23mm;
              height: 23mm;
              border: 0.3mm solid #000;
              padding: 1mm;
            }
            .qr-code {
              width: 100%;
              height: 100%;
            }
            .qr-code img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          </style>
        </head>
        <body>
          <div class="content">
            <div>
              <div class="header">
                <div class="company-name">Armann Systems</div>
              </div>
              
              ${selectedFields.name || selectedFields.serialNumber ? `
                <div class="device-info">
                  ${selectedFields.name ? `
                    <div class="label">Gerätename:</div>
                    <div class="device-name">${asset?.name || 'N/A'}</div>
                  ` : ''}
                  ${selectedFields.serialNumber ? `
                    <div class="label">Seriennummer:</div>
                    <div class="serial-number">${asset?.asset_serial || 'N/A'}</div>
                  ` : ''}
                </div>
              ` : ''}
            </div>
            
            ${selectedFields.support ? `
              <div class="support-info">
                <div class="label">Support:</div>
                <div>+49 9401 91791 500</div>
                <div>support@armann-systems.com</div>
                <div>armann-systems.com</div>
              </div>
            ` : ''}
          </div>
          
          <div class="qr-section">
            <div class="qr-code">
              <img src="${qrDataUrl}" alt="QR Code" />
            </div>
          </div>
        </body>
      </html>
    `;

    printWindow.document.write(content);
    printWindow.document.close();
    
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  };

  return (
    <div className="">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center text-gray-600 hover:text-gray-900 text-sm font-medium"
      >
        <Printer className="w-4 h-4 mr-2" />
        Etikett drucken
        {isExpanded ? <ChevronUp className="w-4 h-4 ml-2" /> : <ChevronDown className="w-4 h-4 ml-2" />}
      </button>

      {isExpanded && (
        <div className="mt-4 space-y-4">
          <div className="flex flex-wrap gap-3">
            <button
              onClick={handlePrint}
              className="inline-flex items-center px-3 py-1.5 bg-blue-600 text-white text-sm rounded hover:bg-blue-700 transition-colors"
            >
              <Printer className="w-4 h-4 mr-1.5" />
              Browser-Druck
            </button>
            <button
              onClick={downloadEZPL}
              className="inline-flex items-center px-3 py-1.5 bg-gray-100 text-gray-700 text-sm rounded hover:bg-gray-200 transition-colors"
            >
              <Download className="w-4 h-4 mr-1.5" />
              EZPL
            </button>
            <button
              onClick={downloadEZPX}
              className="inline-flex items-center px-3 py-1.5 bg-gray-100 text-gray-700 text-sm rounded hover:bg-gray-200 transition-colors"
            >
              <Download className="w-4 h-4 mr-1.5" />
              EZPX
            </button>
          </div>

          <div className="flex flex-wrap gap-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={selectedFields.name}
                onChange={() => setSelectedFields(prev => ({ ...prev, name: !prev.name }))}
                className="rounded border-gray-300 text-blue-600 mr-2"
              />
              <span className="text-sm text-gray-600">Gerätename</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={selectedFields.serialNumber}
                onChange={() => setSelectedFields(prev => ({ ...prev, serialNumber: !prev.serialNumber }))}
                className="rounded border-gray-300 text-blue-600 mr-2"
              />
              <span className="text-sm text-gray-600">Seriennummer</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={selectedFields.support}
                onChange={() => setSelectedFields(prev => ({ ...prev, support: !prev.support }))}
                className="rounded border-gray-300 text-blue-600 mr-2"
              />
              <span className="text-sm text-gray-600">Support-Info</span>
            </label>
          </div>

          <div className="text-xs text-gray-500">
            Format: 62mm x 29mm (300 DPI) • QR-Code enthält Asset-ID
          </div>
        </div>
      )}

      <div className="hidden" ref={qrRef}>
        <QRCodeCanvas 
          value={`https://support.armann-systems.com/assets/${asset?.id || ''}`}
          size={256}
          level="H"
        />
      </div>
    </div>
  );
};

export default PrintLabel;
