import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, Server, Cpu, HardDrive, Shield, Network, LucideMemoryStick, Monitor,
  Calendar, Clock, Building, User, Settings, AlertTriangle, CheckCircle,
  Activity, Thermometer, Globe, Database, AlarmClock, AlertOctagon,
  BatteryCharging, Boxes, Power, Gauge
} from 'lucide-react';
import { apiClient } from '../utils/apiClient';
import PrintLabel from '../components/PrintLabel';
import { MetricsSection } from '../components/MetricsSection';

const StatusBadge = ({ status, size = 'default' }) => {
  const statusColors = {
    'Active': 'bg-green-100 text-green-800',
    'Inactive': 'bg-red-100 text-red-800',
    'Maintenance': 'bg-yellow-100 text-yellow-800',
    'Online': 'bg-green-100 text-green-800',
    'Offline': 'bg-red-100 text-red-800',
    'Warning': 'bg-yellow-100 text-yellow-800',
    'Critical': 'bg-red-100 text-red-800'
  };

  const sizeClasses = {
    'default': 'px-3 py-1 text-sm',
    'large': 'px-4 py-2 text-base'
  };

  return (
    <span className={`${sizeClasses[size]} font-medium rounded-full flex items-center gap-2 ${statusColors[status] || 'bg-gray-100 text-gray-800'}`}>
      {status === 'Online' || status === 'Active' ? <CheckCircle className="w-4 h-4" /> : <AlertTriangle className="w-4 h-4" />}
      {status}
    </span>
  );
};

const MetricCard = ({ icon: Icon, title, value, subtitle, status }) => (
  <div className="bg-white p-4 rounded-xl border shadow-sm hover:shadow-md transition-shadow">
    <div className="flex items-start justify-between">
      <div className="flex-1">
        <p className="text-sm text-gray-500">{title}</p>
        <p className="mt-1 text-xl font-semibold text-gray-700">{value}</p>
        {subtitle && <p className="text-xs text-gray-400 mt-1">{subtitle}</p>}
      </div>
      <div className="flex flex-col items-end gap-2">
        <Icon className="w-5 h-5 text-gray-400" />
        {status && <StatusBadge status={status} />}
      </div>
    </div>
  </div>
);

const TabButton = ({ active, icon: Icon, children, onClick, alert }) => (
  <button
    onClick={onClick}
    className={`flex items-center px-4 py-2 rounded-lg transition-colors relative ${
      active 
        ? 'bg-blue-50 text-blue-700 border border-blue-200' 
        : 'text-gray-600 hover:bg-gray-50'
    }`}
  >
    <Icon className="mr-2 h-4 w-4" />
    {children}
    {alert && (
      <span className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full" />
    )}
  </button>
);

const DetailGrid = ({ items }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    {items.map((item, index) => (
      <div key={index} className="bg-white p-4 rounded-lg border hover:shadow-md transition-shadow">
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="flex items-center gap-2">
            {item.icon && <item.icon className="w-4 h-4 text-gray-400" />}
            <h3 className="text-sm font-medium text-gray-500">{item.label}</h3>
          </div>
          {item.status && <StatusBadge status={item.status} />}
        </div>
        <p className="text-gray-900">{item.value || '-'}</p>
        {item.subtitle && <p className="text-xs text-gray-500 mt-1">{item.subtitle}</p>}
      </div>
    ))}
  </div>
);

const CPUUsageBar = ({ cores }) => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    {cores.map((core, index) => (
      <div key={index} className="bg-white p-4 rounded-lg border">
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-500">CPU Core {index + 1}</span>
          <Cpu className="w-4 h-4 text-gray-400" />
        </div>
        <p className="font-medium text-gray-700">{core.name}</p>
        <p className="text-xs text-gray-500">{core.manufacturer}</p>
      </div>
    ))}
  </div>
);

const AlertSection = ({ triggers }) => {
  const hasAlerts = Object.values(triggers).some(value => value === "true");
  
  if (!hasAlerts) {
    return (
      <div className="bg-green-50 p-4 rounded-lg border border-green-200">
        <div className="flex items-center gap-2">
          <CheckCircle className="w-5 h-5 text-green-500" />
          <p className="text-green-700">Keine aktiven Warnungen</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {Object.entries(triggers).map(([key, value]) => {
        if (value !== "true") return null;
        const alertName = key.replace(/_/g, ' ').replace('triggered', '').trim();
        return (
          <div key={key} className="bg-red-50 p-4 rounded-lg border border-red-200">
            <div className="flex items-center gap-2">
              <AlertOctagon className="w-5 h-5 text-red-500" />
              <div>
                <p className="text-red-700 font-medium">{alertName}</p>
                <p className="text-sm text-red-600">Warning triggered</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const AssetDetailPage = () => {
  const [activeTab, setActiveTab] = useState('system');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [asset, setAsset] = useState(null);
  const { assetId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAsset = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(`/assets/asset/${assetId}`);
        setAsset(response.assets?.asset ?? null);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadAsset();
  }, [assetId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 max-w-7xl mx-auto">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
          <h3 className="font-medium text-gray-700">Error</h3>
          <p className="mt-1">{error}</p>
        </div>
      </div>
    );
  }

  if (!asset) {
    return <div className="p-4">Asset nicht gefunden</div>;
  }

  const k = asset.properties?.kabuto_information ?? {};
  const installDate = new Date(k.install_dates?.os_install ?? 0);
  const lastSync = new Date(k.last_synced_at ?? 0);
  const triggers = asset.rmm_store?.triggers ?? {};
  const hasAlerts = Object.values(triggers).some(value => value === "true");
  
  const metrics = [
    {
      icon: HardDrive,
      title: 'Speicherplatz',
      value: `${k.system_partition?.free_gb ?? 0} GB frei`,
      subtitle: `von ${k.system_partition?.size_gb ?? 0} GB (${k.system_partition?.free_percent ?? 0}%)`,
      status: k.system_partition?.free_percent < 20 ? 'Warning' : 'Online'
    },
    {
      icon: LucideMemoryStick,
      title: 'RAM',
      value: `${k.ram_gb ?? 0} GB`,
      subtitle: `${k.ram?.length ?? 0} Module installiert`
    },
    {
      icon: Cpu,
      title: 'Prozessoren',
      value: `${k.cpu?.length ?? 0} Cores`,
      subtitle: k.cpu?.[0]?.manufacturer ?? 'N/A'
    },
    {
      icon: Calendar,
      title: 'Betriebszeit',
      value: `${Math.floor((new Date() - new Date(k.os?.last_boot_time ?? 0)) / (1000 * 60 * 60 * 24))} Tage`,
      subtitle: `Letzter Neustart: ${new Date(k.os?.last_boot_time ?? 0).toLocaleDateString()}`,
      status: k.os?.pending_reboot ? 'Warning' : 'Online'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header */}
        <div className="mb-8">
          <button
            onClick={() => navigate('/assets')}
            className="mb-6 inline-flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="mr-2 h-5 w-5" />
            Zurück zur Übersicht
          </button>

          <div className="bg-white rounded-xl border p-6 mb-6">
            <div className="flex items-start justify-between mb-4">
              <div>
                <div className="flex items-center gap-3">
                  <Server className="w-8 h-8 text-blue-600" />
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">{asset.name}</h1>
                    <p className="text-gray-500">Asset ID: {asset.id}</p>
                    <p className="text-gray-500">S/N: {asset.asset_serial}</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <PrintLabel asset={asset} />
                <StatusBadge status={k.monitoring ? 'Online' : 'Offline'} size="large" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {metrics.map((metric, index) => (
                <MetricCard key={index} {...metric} />
              ))}
            </div>
          </div>

          {/* Tabs */}
          <div className="bg-white p-1 rounded-lg border mb-6 flex space-x-2">
            <TabButton 
              active={activeTab === 'system'} 
              icon={Server} 
              onClick={() => setActiveTab('system')}
            >
              System
            </TabButton>
            <TabButton 
              active={activeTab === 'hardware'} 
              icon={Cpu} 
              onClick={() => setActiveTab('hardware')}
            >
              Hardware
            </TabButton>
            <TabButton 
              active={activeTab === 'network'} 
              icon={Network} 
              onClick={() => setActiveTab('network')}
            >
              Netzwerk
            </TabButton>
            <TabButton 
              active={activeTab === 'security'} 
              icon={Shield} 
              onClick={() => setActiveTab('security')}
              alert={hasAlerts}
            >
              Sicherheit
            </TabButton>
            <TabButton 
              active={activeTab === 'metrics'} 
              icon={Activity} 
              onClick={() => setActiveTab('metrics')}
            >
              Metriken
            </TabButton>
          </div>

          {/* Content */}
          <div className="bg-white rounded-lg border p-6">
            {activeTab === 'system' && (
              <div className="space-y-8">
                <DetailGrid
                  items={[
                    { icon: Settings, label: "Betriebssystem", value: k.os?.name ?? 'N/A' },
                    { icon: Activity, label: "Build", value: k.os?.build ?? 'N/A' },
                    { icon: Cpu, label: "Architektur", value: k.os?.os_architecture ?? 'N/A' },
                    { icon: Clock, label: "Letzter Neustart", value: new Date(k.os?.last_boot_time ?? 0).toLocaleString() },
                    { icon: Building, label: "Domain", value: k.general?.domain ?? 'N/A' },
                    { icon: User, label: "Letzter Benutzer", value: k.last_user ?? 'N/A' },
                    { icon: Settings, label: "Agent Version", value: k.general?.agent_version ?? 'N/A' },
                    { icon: Calendar, label: "Installationsdatum", value: installDate.toLocaleString() },
                    { icon: Clock, label: "Letzte Synchronisation", value: lastSync.toLocaleString() },
                    { icon: Calendar, label: "BIOS Release", value: new Date(k.install_dates?.bios_release ?? 0).toLocaleString() },
                    { icon: Building, label: "Hersteller", value: k.general?.manufacturer ?? 'N/A' },
                    { icon: Settings, label: "Modell", value: k.general?.model ?? 'N/A' }
                  ]}
                />
              </div>
            )}

            {activeTab === 'hardware' && (
              <div className="space-y-8">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">CPU Konfiguration</h3>
                <CPUUsageBar cores={k.cpu ?? []} />
                
                <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-8">RAM Module</h3>
                <DetailGrid
                  items={k.ram?.map((module, index) => ({
                    icon: LucideMemoryStick,
                    label: `RAM Modul ${index + 1}`,
                    value: module.size,
                    subtitle: `Hersteller: ${module.manufacturer}`
                  })) ?? []}
                />

                <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-8">Speicher & Hardware</h3>
                <DetailGrid
                  items={[
                    { icon: Settings, label: "Motherboard", value: `${k.motherboard?.name ?? 'N/A'} (${k.motherboard?.manufacturer ?? 'N/A'})` },
                    { icon: HardDrive, label: "Festplatte", value: k.hdd?.map(h => h.size).join(', ') ?? 'N/A' },
                    { icon: Database, label: "Speicherplatz gesamt", value: `${k.system_partition?.size_gb ?? 0} GB` },
                    { icon: Database, label: "Freier Speicherplatz", value: `${k.system_partition?.free_gb ?? 0} GB (${k.system_partition?.free_percent ?? 0}%)` },
                    { icon: Building, label: "Festplattentyp", value: k.hdd?.[0]?.manufacturer ?? 'N/A' },
                    { icon: Monitor, label: "Grafikkarte", value: k.video_card?.[0]?.name ?? 'N/A' },
                    { icon: Boxes, label: "Formfaktor", value: k.general?.form_factor ?? 'N/A' },
                    { icon: BatteryCharging, label: "Systemtyp", value: asset.properties?.form_factor ?? 'N/A' }
                  ]}
                />
              </div>
            )}

            {activeTab === 'network' && (
              <div className="space-y-8">
                <DetailGrid
                  items={[
                    { icon: Globe, label: "IP-Adresse (intern)", value: k.primary_adapter?.ipv4 ?? 'N/A' },
                    { icon: Globe, label: "IP-Adresse (extern)", value: k.general?.ip ?? 'N/A' },
                    { icon: Globe, label: "IPv6", value: k.primary_adapter?.ipv6 ?? 'N/A' },
                    { icon: Settings, label: "MAC-Adresse", value: k.primary_adapter?.physical_address ?? 'N/A' },
                    { icon: Network, label: "Gateway", value: k.primary_adapter?.gateway ?? 'N/A' },
                    { icon: Server, label: "DNS Server", value: [k.primary_adapter?.dns1, k.primary_adapter?.dns2].filter(Boolean).join(', ') ?? 'N/A' },
                    { icon: Network, label: "Subnetz", value: k.primary_adapter?.subnet ?? 'N/A' },
                    { icon: Settings, label: "Adapter", value: k.primary_adapter?.description ?? 'N/A' },
                    { icon: Settings, label: "DHCP", value: k.primary_adapter?.dhcp_enabled ? 'Aktiviert' : 'Deaktiviert' },
                    { icon: Settings, label: "Autokonfiguration", value: k.primary_adapter?.autoconfiguration_enabled ? 'Aktiviert' : 'Deaktiviert' }
                  ]}
                />
              </div>
            )}

            {activeTab === 'metrics' && (
              <MetricsSection assetId={asset.id} />
            )}

            {activeTab === 'security' && (
              <div className="space-y-8">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">System Alerts</h3>
                <AlertSection triggers={asset.rmm_store?.triggers ?? {}} />

                <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-8">Windows Updates</h3>
                <DetailGrid
                  items={[
                    { 
                      icon: Clock, 
                      label: "Update Zeitplan", 
                      value: `Tag ${asset.rmm_store?.windows_updates?.wu_schedule?.day ?? 'N/A'}, ${asset.rmm_store?.windows_updates?.wu_schedule?.hour ?? 'N/A'}:00 Uhr`,
                      subtitle: `Aktive Zeit: ${asset.rmm_store?.windows_updates?.wu_schedule?.active_start ?? 'N/A'}-${asset.rmm_store?.windows_updates?.wu_schedule?.active_end ?? 'N/A'} Uhr`
                    },
                    { 
                      icon: AlertTriangle, 
                      label: "Update Status", 
                      value: asset.rmm_store?.windows_updates?.wu_error || "Keine Fehler",
                      status: asset.rmm_store?.windows_updates?.wu_error ? 'Warning' : 'Online'
                    },
                    { 
                      icon: Power, 
                      label: "Neustart ausstehend", 
                      value: k.os?.pending_reboot ? 'Ja' : 'Nein',
                      status: k.os?.pending_reboot ? 'Warning' : 'Online'
                    }
                  ]}
                />

                <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-8">Sicherheitssoftware</h3>
                <DetailGrid
                  items={[
                    { 
                      icon: Shield, 
                      label: "Antivirus Programme", 
                      value: k.av?.map(av => av.name).join(', ') ?? 'N/A'
                    },
                    { 
                      icon: Activity, 
                      label: "Monitoring", 
                      value: k.monitoring ? 'Aktiv' : 'Inaktiv',
                      status: k.monitoring ? 'Online' : 'Offline'
                    },
                    { 
                      icon: Shield, 
                      label: "MAV Status", 
                      value: k.mav ? 'Aktiviert' : 'Deaktiviert',
                      status: k.mav ? 'Online' : 'Warning'
                    },
                    { 
                      icon: Shield, 
                      label: "Firewall", 
                      value: k.av?.some(av => av.name.includes('Firewall')) ? 'Aktiviert' : 'Deaktiviert',
                      status: k.av?.some(av => av.name.includes('Firewall')) ? 'Online' : 'Warning'
                    },
                    {
                      icon: Clock,
                      label: "Letzte Synchronisation",
                      value: new Date(k.last_synced_at ?? 0).toLocaleString()
                    }
                  ]}
                />

                <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-8">Emsisoft Status</h3>
                <DetailGrid
                  items={[
                    { 
                      icon: Shield, 
                      label: "Installation Status", 
                      value: asset.rmm_store?.emsisoft?.device_mav_state?.install_state ?? 'N/A',
                      status: asset.rmm_store?.emsisoft?.device_mav_state?.install_state === 'installed' ? 'Online' : 'Warning'
                    },
                    { 
                      icon: Activity, 
                      label: "Echtzeit-Schutz", 
                      value: asset.rmm_store?.emsisoft?.real_time_protection ? 'Aktiviert' : 'Deaktiviert',
                      status: asset.rmm_store?.emsisoft?.real_time_protection ? 'Online' : 'Warning'
                    },
                    { 
                      icon: Calendar, 
                      label: "Scan Zeitplan", 
                      value: asset.rmm_store?.emsisoft?.scan_schedule ?? 'N/A'
                    },
                    { 
                      icon: Shield, 
                      label: "Anti-Phishing", 
                      value: asset.rmm_store?.emsisoft?.anti_phishing ? 'Aktiviert' : 'Deaktiviert',
                      status: asset.rmm_store?.emsisoft?.anti_phishing ? 'Online' : 'Warning'
                    }
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailPage;
