import { Construction } from 'lucide-react';
export const ConstructionOverlay = () => {
    return (
        <div 
            className="absolute inset-0 bg-black/70 backdrop-blur-sm z-10 flex items-center justify-center"
            style={{ pointerEvents: 'all' }} 
        >
            <div className="bg-white rounded-xl p-8 max-w-md text-center shadow-xl">
                <Construction className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
                <h2 className="text-2xl font-bold text-gray-900 mb-2">
                    In Bearbeitung
                </h2>
                <p className="text-gray-600 mb-4">
                    Diese Seite befindet sich derzeit im Aufbau. Wir arbeiten mit Hochdruck daran, Ihnen bald alle Funktionen zur Verfügung zu stellen.
                </p>
                <div className="text-sm text-gray-500">
                    Voraussichtliche Fertigstellung in Kürze
                </div>
            </div>
        </div>
    );
};