import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { apiClient } from '../utils/apiClient';
import { Loader, LoaderContainer } from '../components/Loader';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';
import { 
  Clock, 
  User, 
  Flag, 
  MessageSquare, 
  Send, 
  ArrowLeft, 
  Monitor, 
  Cpu,
  FileText,
  Image,
  File,
  Download,
  X,
  ZoomIn,
  Maximize2
} from 'lucide-react';

const STATUS_BADGES = {
  'New': 'bg-blue-100 text-blue-800 border-blue-200',
  'In Progress': 'bg-yellow-100 text-yellow-800 border-yellow-200',
  'Resolved': 'bg-green-100 text-green-800 border-green-200',
  'Closed': 'bg-gray-100 text-gray-800 border-gray-200',
  'Customer Reply': 'bg-purple-100 text-purple-800 border-purple-200'
};

const PRIORITY_BADGES = {
  'Low': 'bg-blue-100 text-blue-800 border-blue-200',
  'Normal': 'bg-green-100 text-green-800 border-green-200',
  'High': 'bg-yellow-100 text-yellow-800 border-yellow-200',
  'Emergency': 'bg-red-100 text-red-800 border-red-200'
};

const ImageModal = ({ isOpen, onClose, imageUrl, alt }) => {
  const [loaded, setLoaded] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-75" onClick={onClose}>
      <div className="relative max-w-4xl max-h-[90vh] w-full bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="absolute top-4 right-4 z-10">
          <button
            onClick={onClose}
            className="p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 text-white transition-colors"
          >
            <X size={20} />
          </button>
        </div>
        
        <div className="relative w-full h-full flex items-center justify-center p-4">
          {!loaded && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
            </div>
          )}
          <img
            src={imageUrl}
            alt={alt}
            className={`max-w-full max-h-[80vh] object-contain ${loaded ? 'block' : 'invisible'}`}
            onLoad={() => setLoaded(true)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    </div>
  );
};

const ImagePreview = ({ src, alt, thumbnail }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="relative group">
        <img
          src={thumbnail}
          alt={alt}
          className="w-32 h-32 object-cover rounded-lg cursor-pointer border border-gray-200"
          onClick={() => setIsOpen(true)}
          loading="lazy"
        />
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity rounded-lg flex items-center justify-center" onClick={() => setIsOpen(true)}>
          <Maximize2 className="text-white opacity-0 group-hover:opacity-100 transition-opacity"/>
        </div>
      </div>

      <ImageModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        imageUrl={src}
        alt={alt}
      />
    </>
  );
};

const FileIcon = ({ mimeType }) => {
  switch (true) {
    case mimeType.startsWith('image/'):
      return <Image size={20} className="text-blue-500" />;
    case mimeType.startsWith('text/'):
      return <FileText size={20} className="text-green-500" />;
    default:
      return <File size={20} className="text-gray-500" />;
  }
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const AttachmentsList = ({ attachments }) => {
  
  if (!attachments || attachments.length === 0) return null;

  

  return (
    <div className="mt-3">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {attachments.map((attachment) => {
          const isImage = attachment.content_type?.startsWith('image/');

          if (isImage) {
            return (
              <div 
                key={attachment.id}
                className="flex flex-col space-y-2"
              >
                <ImagePreview 
                  src={attachment.file.url}
                  thumbnail={attachment.file.thumb?.url || attachment.file.url}
                  alt={attachment.file_name}
                />
                <div className="flex items-center justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="text-sm font-medium text-gray-900 truncate">
                      {attachment.file_name}
                    </div>
                    <div className="text-xs text-gray-500">
                      {formatFileSize(attachment.file_size)}
                    </div>
                  </div>
                  <a
                    href={attachment.file.url}
                    download={attachment.file_name}
                    className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-200 rounded-full transition-colors"
                    title="Download"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Download size={16} />
                  </a>
                </div>
              </div>
            );
          }

          return (
            <div 
              key={attachment.id}
              className="flex items-center gap-3 p-2 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <FileIcon mimeType={attachment.content_type} />
              <div className="flex-1 min-w-0">
                <div className="text-sm font-medium text-gray-900 truncate">
                  {attachment.file_name}
                </div>
                <div className="text-xs text-gray-500">
                  {formatFileSize(attachment.file_size)}
                </div>
              </div>
              <a
                href={attachment.file.url}
                download={attachment.file_name}
                className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-200 rounded-full transition-colors"
                title="Download"
              >
                <Download size={16} />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TicketDetailPage = () => {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { user, validateSession } = useAuth();

  const loadTicket = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/tickets/${ticketId}`);
      setTicket(response.ticket);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Fehler beim Laden der Ticketdetails');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTicket();
  }, [ticketId]);

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);

      // Check if firstname and lastname are undefined
      if (!user.firstname || !user.lastname) {
        await validateSession(); // Load user details if undefined
      }

      // Re-check user details after validation
      if (!user.firstname || !user.lastname) {
        toast.error('Fehlende Benutzerinformationen! Bitte versuchen Sie es erneut.');
        return;
      }

      await apiClient.post(`/tickets/${ticketId}/comment`, {
        subject: `Kommentar von ${user.firstname} ${user.lastname}`,
        body: newComment,
        tech: `${user.firstname} ${user.lastname}`,
        hidden: false,
        do_not_email: true,
      });
      toast.success('Kommentar wurde hinzugefügt');
      setNewComment('');
      setTicket((prevTicket) => ({
        ...prevTicket,
        comments: [{
          subject: `Kommentar von ${user.firstname} ${user.lastname}`,
          body: newComment,
          created_at: new Date().toISOString(),
        }, ...prevTicket.comments], // Add new comment at the top
      }));
    } catch (err) {
      toast.error('Fehler beim Hinzufügen des Kommentars');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <LoaderContainer text="Ticket wird geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          Fehler beim Laden des Tickets: {error}
          <button
            onClick={loadTicket}
            className="ml-4 px-3 py-1 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Ticket Details - Armann Systems GmbH</title>
      </Helmet>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <Link 
            to="/tickets" 
            className="inline-flex items-center text-sm text-gray-600 hover:text-gray-900 mb-4"
          >
            <ArrowLeft size={16} className="mr-1" />
            Zurück zur Übersicht
          </Link>
          <div className="flex items-start justify-between">
            <h1 className="text-2xl font-bold text-gray-900">
              Ticket #{ticket.number}
            </h1>
            <div className="flex gap-2">
              <span className={`px-3 py-1 text-sm font-medium rounded-full border ${
                STATUS_BADGES[ticket.status]
              }`}>
                {ticket.status}
              </span>
              <span className={`px-3 py-1 text-sm font-medium rounded-full border ${
                PRIORITY_BADGES[ticket.priority]
              }`}>
                {ticket.priority}
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Content */}
          <div className="lg:col-span-2 space-y-6">
            {/* Ticket Details */}
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
              <h2 className="text-xl font-bold text-gray-900 mb-4">{ticket.subject}</h2>
              <div className="space-y-4">
                <div className="flex items-center text-sm text-gray-700">
                  <User size={16} className="mr-2" />
                  <span>Kunde: {ticket.customer.business_then_name}</span>
                </div>
                <div className="flex items-center text-sm text-gray-700">
                  <Clock size={16} className="mr-2" />
                  <span>Erstellt am: {new Date(ticket.created_at).toLocaleString()}</span>
                </div>
              </div>
              
              {/* Ticket Attachments */}
              {ticket.attachments && ticket.attachments.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-sm font-medium text-gray-900 mb-3">Ticket Anhänge</h3>
                  <AttachmentsList attachments={ticket.attachments} />
                </div>
              )}
            </div>

            {ticket.assets && ticket.assets.length > 0 && (
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  <div className="flex items-center gap-2">
                    <Monitor size={20} />
                    Betroffene Geräte
                  </div>
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {ticket.assets.map((asset) => (
                    <Link
                      key={asset.id}
                      to={`/assets/${asset.id}`}
                      className="block transition-transform hover:scale-[1.02]"
                    >
                      <div className="bg-gray-50 rounded-lg p-4 border border-gray-200 hover:border-blue-300 transition-colors">
                        <div className="flex items-start gap-3">
                          <div className="p-2 bg-blue-100 rounded-lg">
                            <Cpu size={20} className="text-blue-600" />
                          </div>
                          <div className="flex-1 min-w-0">
                            <h4 className="text-sm font-medium text-gray-900 truncate">
                              {asset.name}
                            </h4>
                            {asset.asset_serial && (
                              <p className="text-sm text-gray-600 mt-1">
                                Seriennummer: {asset.asset_serial}
                              </p>
                            )}
                            {asset.asset_type && (
                              <p className="text-sm text-gray-600">
                                Typ: {asset.asset_type}
                              </p>
                            )}
                            <div className="mt-2 text-xs text-gray-500">
                              Erstellt: {new Date(asset.created_at).toLocaleDateString()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {/* Comments Section */}
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                <div className="flex items-center gap-2">
                  <MessageSquare size={20} />
                  Kommentare
                </div>
              </h3>
              
              {ticket.comments.length === 0 ? (
                <p className="text-gray-700 text-center py-4">Noch keine Kommentare vorhanden.</p>
              ) : (
                <div className="space-y-4">
                  {ticket.comments.map((comment, index) => {
                    // Find attachments created around the same time as the comment
                    const commentAttachments = ticket.attachments?.filter(attachment => {
                      const attachmentTime = new Date(attachment.created_at).getTime();
                      const commentTime = new Date(comment.created_at).getTime();
                      // Consider attachments created within 1 minute of the comment
                      return Math.abs(attachmentTime - commentTime) < 60000;
                    });

                    return (
                      <div key={index} className="bg-gray-50 rounded-lg p-4 text-sm">
                        <div className="flex justify-between items-start mb-2">
                          <strong className="text-gray-900">{comment.subject}</strong>
                          <span className="text-xs text-gray-700">
                            {new Date(comment.created_at).toLocaleString()}
                          </span>
                        </div>
                        <p className="text-gray-900 whitespace-pre-wrap">{comment.body}</p>
                        {commentAttachments && commentAttachments.length > 0 && (
                          <AttachmentsList attachments={commentAttachments} />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Comment Form */}
              <form onSubmit={handleSubmitComment} className="mt-6">
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  className="w-full p-3 rounded-lg border border-gray-200 text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  placeholder="Schreiben Sie einen Kommentar..."
                  rows="4"
                  required
                />
                <div className="mt-2 flex justify-end">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors"
                  >
                    {submitting ? (
                      <Loader size="small" className="mr-2" />
                    ) : (
                      <Send size={16} className="mr-2" />
                    )}
                    Kommentar hinzufügen
                  </button>
                </div>
              </form>
            </div>
          </div>

           {/* Sidebar */}
           <div className="lg:col-span-1">
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 space-y-6">
              {/* Ticket Details */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Ticket Details</h3>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium text-gray-700">Priorität</label>
                    <div className="mt-1 text-gray-900">{ticket.priority}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">Status</label>
                    <div className="mt-1 text-gray-900">{ticket.status}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">Zuletzt aktualisiert</label>
                    <div className="mt-1 text-gray-900">
                      {new Date(ticket.updated_at).toLocaleString()}
                    </div>
                  </div>
                  {ticket.due_date && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">Fälligkeitsdatum</label>
                      <div className="mt-1 text-gray-900">
                        {new Date(ticket.due_date).toLocaleString()}
                      </div>
                    </div>
                  )}
                  {ticket.problem_type && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">Problem Typ</label>
                      <div className="mt-1 text-gray-900">{ticket.problem_type}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Customer Information */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Kundeninformationen</h3>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium text-gray-700">Firma</label>
                    <div className="mt-1 text-gray-900">{ticket.customer.business_name}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">Kontakt</label>
                    <div className="mt-1 text-gray-900">
                      {ticket.contact.firstname} {ticket.contact.lastname}
                    </div>
                  </div>
                  {ticket.contact.name && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">Ansprechpartner</label>
                      <div className="mt-1 text-gray-900">
                        
                          {ticket.contact.name}
                        
                      </div>
                    </div>
                  )}
                  {ticket.contact.email && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">E-Mail</label>
                      <div className="mt-1 text-gray-900">
                        <a 
                          href={`mailto:${ticket.contact.email}`}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          {ticket.contact.email}
                        </a>
                      </div>
                    </div>
                  )}
                  {ticket.contact.phone && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">Telefon</label>
                      <div className="mt-1 text-gray-900">
                        <a 
                          href={`tel:${ticket.contact.phone}`}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          {ticket.contact.phone}
                        </a>
                      </div>
                    </div>
                  )}
                  {(ticket.customer.address || ticket.customer.city) && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">Adresse</label>
                      <div className="mt-1 text-gray-900">
                        {ticket.customer.address && (
                          <div>{ticket.customer.address}</div>
                        )}
                        {ticket.customer.address_2 && (
                          <div>{ticket.customer.address_2}</div>
                        )}
                        {ticket.customer.city && (
                          <div>
                            {ticket.customer.zip} {ticket.customer.city}
                          </div>
                        )}
                        {ticket.customer.state && (
                          <div>{ticket.customer.state}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Asset Summary */}
              {ticket.assets && ticket.assets.length > 0 && (
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">Asset Übersicht</h3>
                  <div className="text-sm text-gray-700">
                    Anzahl betroffener Geräte: {ticket.assets.length}
                  </div>
                </div>
              )}

              {/* Attachments Summary */}
              {ticket.attachments && ticket.attachments.length > 0 && (
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">Anhänge</h3>
                  <div className="text-sm text-gray-700">
                    Anzahl der Anhänge: {ticket.attachments.length}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetailPage;
