import React from 'react';
import { Activity } from 'lucide-react';

const HealthScoreGauge = ({ score }) => {
  const color = score >= 80 ? 'green' : score >= 60 ? 'yellow' : 'red';
  return (
    <div className="relative w-24 h-24">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-gray-200"
          strokeWidth="10"
          stroke="currentColor"
          fill="transparent"
          r="40"
          cx="50"
          cy="50"
        />
        <circle
          className={`text-${color}-500 transition-all duration-300`}
          strokeWidth="10"
          strokeDasharray={251.2}
          strokeDashoffset={251.2 * (1 - score / 100)}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="40"
          cx="50"
          cy="50"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-xl font-semibold text-gray-600">{score}</span>
      </div>
    </div>
  );
};
export const HealthScores = ({ healthScores }) => (
    <div className="mb-8">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">
        <div className="flex items-center gap-2">
          <Activity className="text-blue-500" />
          System Gesundheit
        </div>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {healthScores?.map((asset) => (
          <div key={asset.id} className="bg-white rounded-lg border p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="font-medium text-gray-900">{asset.name}</h3>
                <p className="text-sm text-gray-500">
                  {asset.manufacturer} {asset.model}
                </p>
              </div>
              <HealthScoreGauge score={asset.health_score} />
            </div>
            
            <div className="space-y-2">
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-600">CPU Auslastung</span>
                <span className={`font-medium ${
                  asset.avg_cpu_usage > 80 ? 'text-red-600' : 
                  asset.avg_cpu_usage > 60 ? 'text-yellow-600' : 'text-green-600'
                }`}>
                  {Math.round(asset.avg_cpu_usage)}%
                </span>
              </div>
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-600">RAM Auslastung</span>
                <span className={`font-medium ${
                  asset.avg_ram_usage > 80 ? 'text-red-600' : 
                  asset.avg_ram_usage > 60 ? 'text-yellow-600' : 'text-green-600'
                }`}>
                  {Math.round(asset.avg_ram_usage)}%
                </span>
              </div>
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-600">Speicher</span>
                <span className={`font-medium ${
                  asset.disk_free_percent < 15 ? 'text-red-600' : 
                  asset.disk_free_percent < 30 ? 'text-yellow-600' : 'text-green-600'
                }`}>
                  {Math.round(asset.disk_free_percent)}% frei
                </span>
              </div>
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-600">Uptime</span>
                <span className="font-medium text-gray-600">{asset.uptime_days} Tage</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );