import React, { useContext, useState } from 'react';
import { CheckCircle, AlertOctagon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { NotificationContext } from '../contexts/NotificationContext';

const NotificationDropdown = () => {
  const { newNotifications = [], oldNotifications = [], markNotificationsAsRead } = useContext(NotificationContext);
  const [visibleCount, setVisibleCount] = useState(5);

  const toggleVisibleCount = () => {
    setVisibleCount(visibleCount === 5 ? 15 : 5);
  };

  return (
    <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg">
      <div className="p-4">
        <h4 className="text-sm font-medium text-gray-900">Benachrichtigungen</h4>
        <ul className="mt-2 space-y-2">
          {newNotifications.length > 0 ? (
            newNotifications.slice(0, visibleCount).map((alert, index) => (
              <li key={index} className="text-sm text-gray-700 flex items-center gap-2 p-2 bg-red-50 border border-red-200 rounded-md">
                <AlertOctagon className="w-4 h-4 text-red-500" />
                <span>
                  {alert.description || 'Keine Beschreibung verfügbar'}
                  {alert.asset_id && (
                    <Link to={`/assets/${alert.asset_id}`} className="text-blue-500 hover:underline ml-2">
                      [Asset]
                    </Link>
                  )}
                  {alert.ticket_number && (
                    <Link to={`/tickets/${alert.ticket_number}`} className="text-blue-500 hover:underline ml-2">
                      [Ticket]
                    </Link>
                  )}
                </span>
              </li>
            ))
          ) : (
            <li className="text-sm text-gray-500 flex items-center gap-2 p-2 bg-green-50 border border-green-200 rounded-md">
              <CheckCircle className="w-4 h-4 text-green-500" />
              Keine neuen Warnungen
            </li>
          )}
          {oldNotifications.length > 0 && (
            <li className="text-xs text-gray-400 mt-2">Ältere Warnungen:</li>
          )}
          {oldNotifications.slice(0, visibleCount).map((alert, index) => (
            <li key={index} className="text-xs text-gray-500 flex items-center gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md">
              <AlertOctagon className="w-3 h-3 text-gray-400" />
              <span>
                {alert.description || 'Keine Beschreibung verfügbar'}
                {alert.asset_id && (
                  <Link to={`/assets/${alert.asset_id}`} className="text-blue-500 hover:underline ml-2">
                    [Asset]
                  </Link>
                )}
                {alert.ticket_number && (
                  <Link to={`/tickets/${alert.ticket_number}`} className="text-blue-500 hover:underline ml-2">
                    [Ticket]
                  </Link>
                )}
              </span>
            </li>
          ))}
          <li className="text-xs text-blue-500 mt-2">
            <button onClick={toggleVisibleCount} className="hover:underline">
              {visibleCount === 5 ? '+ Mehr anzeigen' : '- Weniger anzeigen'}
            </button>
          </li>
          <li className="text-xs text-blue-500 mt-2">
            <Link to="/notifications" className="hover:underline" onClick={markNotificationsAsRead}>
              Alle Benachrichtigungen anzeigen
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NotificationDropdown;
