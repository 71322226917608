import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { toast } from 'react-toastify';
import { LoaderContainer } from '../components/Loader';
import { ArrowLeft } from 'lucide-react';

export const CreateTicketPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [contacts, setContacts] = useState([]);
  
  const [formData, setFormData] = useState({
    subject: '',
    problem_type: '',
    priority: 'Normal',
    status: 'New',
    customer_id: user.syncroCompanyId,
    asset_ids: [], // Änderung von asset_id zu asset_ids als Array
    contact_id: '',
    comment: '',
    properties: {}
  });

  // Load customer assets and contacts
  useEffect(() => {
    const loadCustomerData = async () => {
      try {
        const [assetsResponse, contactsResponse] = await Promise.all([
          apiClient.get(`/assets/${user.syncroCompanyId}`),
          apiClient.get(`/contacts/customer/${user.syncroCompanyId}`)
        ]);
        
        setAssets(assetsResponse.assets || []);
        setContacts(contactsResponse.contacts || []);
      } catch (error) {
        toast.error('Fehler beim Laden der Kundendaten');
      }
    };

    loadCustomerData();
  }, [user.syncroCompanyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'asset_id') {
      // Wenn ein Asset ausgewählt wird, fügen Sie die ID als Array hinzu
      setFormData(prev => ({
        ...prev,
        asset_ids: value ? [parseInt(value)] : [] // Konvertiere zu number und wrape in Array
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await apiClient.post('/tickets', formData);
      toast.success('Ticket erfolgreich erstellt');
      navigate(`/tickets/${response.ticket.id}`);
    } catch (error) {
      toast.error(error.message || 'Fehler beim Erstellen des Tickets');
      setLoading(false);
    }
  };

  if (loading) {
    return <LoaderContainer text="Ticket wird erstellt..." />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <button
            onClick={() => navigate(-1)}
            className="mb-4 inline-flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft size={20} className="mr-2" />
            Zurück
          </button>
          <h1 className="text-2xl font-bold text-gray-900">Neues Ticket erstellen</h1>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="bg-white shadow-sm rounded-xl border border-gray-100 p-6">
          <div className="space-y-6">
            {/* Subject */}
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                Betreff *
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                required
                value={formData.subject}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            {/* Problem Type */}
            <div>
              <label htmlFor="problem_type" className="block text-sm font-medium text-gray-700">
                Problem-Typ *
              </label>
              <select
                id="problem_type"
                name="problem_type"
                required
                value={formData.problem_type}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Bitte wählen</option>
                <option value="Hardware">Hardware</option>
                <option value="Software">Software</option>
                <option value="Network">Netzwerk</option>
                <option value="Email">E-Mail</option>
                <option value="Other">Sonstiges</option>
              </select>
            </div>

            {/* Priority */}
            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                Priorität
              </label>
              <select
                id="priority"
                name="priority"
                value={formData.priority}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="Low">Niedrig</option>
                <option value="Normal">Normal</option>
                <option value="High">Hoch</option>
                <option value="Emergency">Notfall</option>
              </select>
            </div>

            {/* Asset */}
            {assets.length > 0 && (
              <div>
                <label htmlFor="asset_id" className="block text-sm font-medium text-gray-700">
                  Betroffenes Gerät
                </label>
                <select
                  id="asset_id"
                  name="asset_id"
                  value={formData.asset_id}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Kein Gerät auswählen</option>
                  {assets.map(asset => (
                    <option key={asset.id} value={asset.id}>
                      {asset.name} {asset.asset_serial ? `(${asset.asset_serial})` : ''}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Contact */}
            {contacts.length > 0 && (
              <div>
                <label htmlFor="contact_id" className="block text-sm font-medium text-gray-700">
                  Ansprechpartner
                </label>
                <select
                  id="contact_id"
                  name="contact_id"
                  value={formData.contact_id}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Keinen Ansprechpartner auswählen</option>
                  {contacts.map(contact => (
                    <option key={contact.id} value={contact.id}>
                      {contact.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Comment */}
            <div>
              <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                Beschreibung *
              </label>
              <textarea
                id="comment"
                name="comment"
                required
                rows={4}
                value={formData.comment}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="pt-4">
              <button
                type="submit"
                disabled={loading}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors disabled:opacity-50"
              >
                Ticket erstellen
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTicketPage;