import React from 'react';
import { Shield } from 'lucide-react';

export const SoftwareIssues = ({ issues }) => (
  <div className="mb-8">
    <h2 className="text-lg font-semibold text-gray-900 mb-4">
      <div className="flex items-center gap-2">
        <Shield className="text-purple-500" />
        Software Kompatibilitätsprobleme
      </div>
    </h2>
    <div className="bg-white rounded-lg border p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {issues?.map((issue) => (
          <div key={issue.id} className="border rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium text-gray-900">{issue.name}</h3>
              <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                issue.issue_type === 'EOL OS Version' ? 'bg-red-100 text-red-800' :
                issue.issue_type === 'Outdated OS Version' ? 'bg-yellow-100 text-yellow-800' :
                'bg-blue-100 text-blue-800'
              }`}>
                {issue.issue_type}
              </span>
            </div>
            <div className="space-y-2 text-sm">
              <div className="flex justify-between">
                <span className="text-gray-600">OS Version</span>
                <span className="text-gray-900">{JSON.parse(issue.os_version)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Build</span>
                <span className="text-gray-900">{JSON.parse(issue.os_build)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">RAM</span>
                <span className="text-gray-900">{JSON.parse(issue.ram_gb)} GB</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);