import React, { useMemo } from 'react';
import { Activity, Cpu, Memory, AlertTriangle } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

export const PerformanceTrendsChart = ({ data }) => {
  const processedData = useMemo(() => {
    // Group by date and calculate averages
    const groupedByDate = data.reduce((acc, entry) => {
      const date = new Date(entry.date).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = {
          date,
          avgCpu: 0,
          avgRam: 0,
          totalCpuIncidents: 0,
          totalRamIncidents: 0,
          count: 0
        };
      }
      acc[date].avgCpu += Number(entry.avg_cpu) || 0;
      acc[date].avgRam += Number(entry.avg_ram) || 0;
      acc[date].totalCpuIncidents += Number(entry.high_cpu_incidents) || 0;
      acc[date].totalRamIncidents += Number(entry.high_ram_incidents) || 0;
      acc[date].count += 1;
      return acc;
    }, {});

    // Calculate final averages
    return Object.values(groupedByDate).map(day => ({
      date: day.date,
      avgCpu: Math.round(day.avgCpu / day.count * 100) / 100,
      avgRam: Math.round(day.avgRam / day.count * 100) / 100,
      cpuIncidents: day.totalCpuIncidents,
      ramIncidents: day.totalRamIncidents
    }));
  }, [data]);

  return (
    <div className="bg-white rounded-lg border p-6">
      <div className="flex items-center gap-2 mb-6">
        <Activity className="h-5 w-5 text-blue-500" />
        <h2 className="text-lg font-semibold text-gray-900">Performance-Trends (30 Tage)</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Usage Trends */}
        <div className="bg-white rounded-lg border p-4">
          <div className="flex items-center gap-2 mb-4">
            <Cpu className="h-4 w-4 text-blue-500" />
            <h3 className="text-sm font-medium text-gray-700">Durchschnittliche Auslastung</h3>
          </div>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={processedData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis dataKey="date" stroke="#666" />
                <YAxis stroke="#666" unit="%" />
                <Tooltip 
                  contentStyle={{ 
                    background: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                  }}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="avgCpu" 
                  name="CPU" 
                  stroke="#3b82f6" 
                  strokeWidth={2}
                  dot={false}
                />
                <Line 
                  type="monotone" 
                  dataKey="avgRam" 
                  name="RAM" 
                  stroke="#8b5cf6" 
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Incidents Trends */}
        <div className="bg-white rounded-lg border p-4">
          <div className="flex items-center gap-2 mb-4">
            <AlertTriangle className="h-4 w-4 text-yellow-500" />
            <h3 className="text-sm font-medium text-gray-700">Leistungsprobleme</h3>
          </div>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={processedData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis dataKey="date" stroke="#666" />
                <YAxis stroke="#666" />
                <Tooltip 
                  contentStyle={{ 
                    background: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                  }}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="cpuIncidents" 
                  name="CPU Vorfälle" 
                  stroke="#ef4444" 
                  strokeWidth={2}
                  dot={false}
                />
                <Line 
                  type="monotone" 
                  dataKey="ramIncidents" 
                  name="RAM Vorfälle" 
                  stroke="#f59e0b" 
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

